/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
import { CRUDoperations } from "../CRUD/crud";

const cuisines = {
  create: async (value) => {
    try {
      const result = await CRUDoperations.create("cuisines", value);
      return result;
    } catch (err) {
      console.log(err);
    }
  },
  read: async () => {
    try {
      const Data = await CRUDoperations.read("cuisines");
      console.log(Data);
      return Data;
    } catch (err) {
      throw err;
    }
  },
  update: async (docId, updatedData) => {
    try {
      const updating = await CRUDoperations.update(
        "cuisines",
        docId,
        updatedData
      );
      return updating;
    } catch (err) {
      throw err;
    }
  },
  delete: async (docId) => {
    try {
      const deleting = await CRUDoperations.delete("cuisines", docId);
      return deleting;
    } catch (err) {
      throw err;
    }
  },
};

export default cuisines;
