import React from "react";

import SubdomainRoutes from "./SubdomainRoutes";
import CreatorRoutes from "./CreatorRoutes";

const CreatorAllRoutes = function CreatorAllRoutes(props) {
  const { domains } = props;

  return domains ? <SubdomainRoutes /> : <CreatorRoutes />;
};

export { CreatorAllRoutes };
