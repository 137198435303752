import { createSlice } from "@reduxjs/toolkit";
import { getArticlesPaginationDataFromDB } from "../../actions/articleActions/userArticlePagination";

// async thunk initial state
const initialState = {
  articlePaginationData: [],
  status: "idle",
  error: null,
};

export const articlePaginationSlice = createSlice({
  name: "articlePagination",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setArticlePaginationData(state, action) {
      state.articlePaginationData = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(
        getArticlesPaginationDataFromDB.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.articlePaginationData = payload;
        }
      )
      .addCase(getArticlesPaginationDataFromDB.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getArticlesPaginationDataFromDB.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setArticlePaginationData } = articlePaginationSlice.actions;

export default articlePaginationSlice.reducer;
