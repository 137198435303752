/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { Typography, Grid, Divider, Avatar, IconButton } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";

// images
import CloseIcon from "@mui/icons-material/Close";
import LogoutUnColored from "../../../assets/images/CreatorScreen/LogoutUnColored.svg";
import SettingColored from "../../../assets/images/CreatorScreen/SettingsColored.svg";
import SettingUnColored from "../../../assets/images/CreatorScreen/SettingsUnColored.svg";
import DollarUnColored from "../../../assets/images/CreatorScreen/DollarUnColored.svg";
import VectorColored from "../../../assets/images/CreatorScreen/VectorColored.svg";
import VectorUnColored from "../../../assets/images/CreatorScreen/VectorUnColored.svg";
import CommunityUnColored from "../../../assets/images/CreatorScreen/CommunityUnColored.svg";
import EditUnColored from "../../../assets/images/CreatorScreen/EditUnColored.svg";
import EditColored from "../../../assets/images/CreatorScreen/EditColored.svg";
import StarUnColored from "../../../assets/images/CreatorScreen/StarUnColored.svg";
import StarColored from "../../../assets/images/CreatorScreen/StarColored.svg";

// modal hook
import useModalOptions from "../../../hooks/useModalOptions";
import MuiLogoutModal from "../../../components/MuiLogoutModal";
import { MobileSteppers } from "../../../components/MuiMobileStepper";
import { setProfileDashboard } from "../../../redux/slice/userSlice";
import useDashBoardHook from "../Dashboard/useDashBoardHook";

// styles
import {
  GridCreatorList,
  ImageCreatorList,
  LinkCreatorList,
  LinkCreatorLogout,
  LinkCreatorsList,
  ComingSoonButton,
} from "./Styles";

const ProfileStepper = styled(MobileSteppers)({
  "& .MuiMobileStepper-progress": {
    width: "100%",
    background: "#e2e2e2",
  },
});

function CreatorNavbar() {
  const [hover, setHover] = useState(false);
  const location = useLocation();

  const currentScreen = location.pathname;
  const user = useSelector((state) => state.user.userDetails);
  const spaceDetails = useSelector((state) => state.spaceDetails);
  const { id } = user;
  const { spaceDetailsData } = spaceDetails;
  const { handleOpen, open, handleClose } = useModalOptions();
  const {
    dispatch,
    totalSteps,
    percentage,
    percentageValue,
    profileDashboardTrueRFalse,
  } = useDashBoardHook();

  const handleLogoutOpen = () => {
    handleOpen();
  };

  function CreateMode() {
    return (
      <Grid container direction="column" pt={3}>
        <Grid item container p={1} pr={0.5}>
          <GridCreatorList item>
            <LinkCreatorList to="/">
              <ImageCreatorList
                alt=""
                src={currentScreen === "/" ? VectorColored : VectorUnColored}
              />
              <Typography
                variant="subHeading4"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "8px",
                }}
              >
                Dashboard
              </Typography>
            </LinkCreatorList>
          </GridCreatorList>
          <GridCreatorList item>
            <LinkCreatorList to="/creator/content-creation">
              <ImageCreatorList
                src={
                  currentScreen === "/creator/content-creation"
                    ? EditColored
                    : EditUnColored
                }
                alt=""
              />
              <Typography
                variant="subHeading4"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "8px",
                }}
              >
                Content Creation
              </Typography>
            </LinkCreatorList>
          </GridCreatorList>
          <GridCreatorList item>
            <LinkCreatorList to="/creator/editor">
              <ImageCreatorList
                src={
                  currentScreen === "/creator/editor"
                    ? StarColored
                    : StarUnColored
                }
                alt=""
              />
              <Typography
                variant="subHeading4"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "8px",
                }}
              >
                Site Editor
              </Typography>
            </LinkCreatorList>
          </GridCreatorList>
          <GridCreatorList item ml={1}>
            <LinkCreatorsList>
              <ImageCreatorList
                style={{ opacity: "0.5" }}
                src={DollarUnColored}
                alt=""
              />
              <Typography
                variant="subHeading2"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "4px",
                  marginRight: "4px",
                  opacity: "0.5",
                }}
              >
                Earnings
              </Typography>
              <ComingSoonButton sx={{ marginLeft: "15px !important" }}>
                <Typography variant="subtitle2" color="#EF4823">
                  Coming Soon!
                </Typography>
              </ComingSoonButton>
            </LinkCreatorsList>
          </GridCreatorList>
          <Grid item ml={5}>
            <LinkCreatorsList>
              <ImageCreatorList
                style={{ opacity: "0.5" }}
                src={CommunityUnColored}
                alt=""
              />
              <Typography
                variant="subHeading4"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "6px",
                  opacity: "0.5",
                }}
              >
                Community
              </Typography>
              <ComingSoonButton>
                <Typography variant="subtitle2" color="#EF4823">
                  Coming Soon!
                </Typography>
              </ComingSoonButton>
            </LinkCreatorsList>
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <Grid item container p={1} pt={2}>
          <GridCreatorList item>
            <LinkCreatorList to="/creator/settings">
              <ImageCreatorList
                src={
                  currentScreen === "/creator/settings"
                    ? SettingColored
                    : SettingUnColored
                }
                alt=""
              />
              <Typography
                variant="subHeading4"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "8px",
                }}
              >
                Settings
              </Typography>
            </LinkCreatorList>
          </GridCreatorList>
          <GridCreatorList item>
            <LinkCreatorLogout onClick={handleLogoutOpen}>
              <ImageCreatorList src={LogoutUnColored} alt="" />
              <Typography
                variant="subHeading4"
                sx={{
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                  marginLeft: "8px",
                }}
              >
                Log Out
              </Typography>
            </LinkCreatorLogout>
          </GridCreatorList>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        sx={{
          position: "fixed",
          background: "#fafbfc",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRight: "1px solid #dddddd",
          width: { xs: "90px", sm: "280px", md: "280px", lg: "280px" },
          transition: "300ms linear",
        }}
      >
        <Grid item>
          <MuiLogoutModal open={open} handleClose={handleClose} />
          <Grid container xs={12}>
            <Grid
              item
              pt={3}
              sx={{
                height: "175px",
                backgroundColor: "#f8f5f2",
                width: "100%",
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                },
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item sx={{ display: "flex", justifyContent: "center" }}>
                {spaceDetailsData?.creatorProfileImage ? (
                  <Avatar
                    src={spaceDetailsData?.creatorProfileImage}
                    variant="circle"
                    sx={{ width: "72px", height: "72px" }}
                  />
                ) : (
                  <Avatar
                    sx={{ width: "72px", height: "72px" }}
                    variant="circle"
                  >
                    {spaceDetailsData?.creatorName &&
                      spaceDetailsData?.creatorName.slice(0, 1).toUpperCase()}
                  </Avatar>
                )}
              </Grid>
              <Grid item sx={{ textAlign: "center", mt: "10px" }}>
                <Grid item>
                  <Typography variant="h4" sx={{ fontSize: "14px" }}>
                    {spaceDetailsData?.creatorName &&
                      spaceDetailsData?.creatorName}
                  </Typography>
                </Grid>
                <a
                  // eslint-disable-next-line no-template-curly-in-string
                  href={`https://${user?.webURL}.klynk.dev`}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#FF8469" }}
                  rel="noreferrer"
                >
                  <Grid item>
                    <Typography variant="subtitle1" sx={{ color: "#FF8469" }}>
                      View My Site
                    </Typography>
                  </Grid>
                </a>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                height: "183px",
                backgroundColor: "#f8f5f2",
                width: "100%",
                display: {
                  xs: "flex",
                  sm: "none",
                  md: "none",
                  lg: "none",
                },
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid item>
                {spaceDetailsData?.creatorProfileImage ? (
                  <Avatar
                    src={spaceDetailsData?.creatorProfileImage}
                    variant="circle"
                    sx={{ width: "72px", height: "72px" }}
                  />
                ) : (
                  <Avatar
                    sx={{ width: "72px", height: "72px" }}
                    variant="circle"
                  >
                    {spaceDetailsData?.creatorName &&
                      spaceDetailsData?.creatorName.slice(0, 1).toUpperCase()}
                  </Avatar>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{CreateMode()}</Grid>
          {profileDashboardTrueRFalse && (
            <Grid item px={3}>
              <Grid
                container
                direction="column"
                p={2}
                sx={{ border: "1px solid #CECECE", borderRadius: "8px" }}
              >
                <Grid
                  item
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  mb="12px"
                >
                  {percentageValue === "100" ? (
                    <>
                      <Typography variant="subtitle2">
                        profile setup Completed
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: "#EF4823" }}>
                        {percentageValue}%
                      </Typography>
                    </>
                  ) : (
                    <Grid container xs={12} justifyContent="space-between">
                      <Grid item>
                        <Typography variant="subHeading3">
                          Your Profile
                        </Typography>
                      </Grid>
                      {hover ? (
                        <Grid item>
                          <IconButton
                            sx={{ width: "4px", height: "4px" }}
                            onClick={() => dispatch(setProfileDashboard(false))}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          height="20px"
                          sx={{ cursor: "pointer" }}
                          onMouseEnter={() => setHover(!hover)}
                        >
                          <Typography variant="subHeading3" color="#EF4823">
                            {percentageValue} %
                          </Typography>
                        </Grid>
                      )}
                      {/* <Grid item>
                        <IconButton
                          onClick={() => dispatch(setProfileDashboard(false))}
                        >
                          <CloseIcon sx={{ width: "14px", height: "20px" }} />
                        </IconButton>
                      </Grid> */}
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <ProfileStepper
                    variant="progress"
                    steps={totalSteps + 1}
                    activeStep={percentage}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* <Grid
        item
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: 7,
        }}
      >
        <Grid item>
          <Typography
            variant="subtitle2"
            sx={{
              color: "#797979",
            }}
          >
            Recipe Platform Version 1.0
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Link to="/terms-and-services" style={{ textDecoration: "none" }}>
            <Typography variant="subtitle2" sx={{ color: "#797979" }}>
              T&C |{" "}
            </Typography>
          </Link>
          <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
            <Typography variant="subtitle2" sx={{ color: "#797979" }}>
              Privacy
            </Typography>
          </Link>
        </Grid>
      </Grid> */}
      </Grid>
      <Outlet />
    </>
  );
}

export default CreatorNavbar;
