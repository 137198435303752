import { createSlice } from "@reduxjs/toolkit";
import { getRecipePaginationDataFromDB } from "../../actions/recipeActions/userRecipePagination";

// async thunk initial state
const initialState = {
  recipePaginationData: [],
  status: "idle",
  error: null,
};

export const recipePaginationSlice = createSlice({
  name: "recipePagination",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setRecipePaginationData(state, action) {
      state.recipePaginationData = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(
        getRecipePaginationDataFromDB.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.recipePaginationData = payload;
        }
      )
      .addCase(getRecipePaginationDataFromDB.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRecipePaginationDataFromDB.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setRecipePaginationData } = recipePaginationSlice.actions;

export default recipePaginationSlice.reducer;
