/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { getUserSpaceDetailsData } from "../../actions/spaceActions/userAsyncSpaceDetails";

const spaceDetailsData =
  window.localStorage.getItem("userSpaceDetails") !== []
    ? JSON.parse(window.localStorage.getItem("userSpaceDetails"))
    : null;

// async thunk initial state
const initialState = {
  spaceDetailsData,
  status: "idle",
  error: null,
  loading: true,
  liveSitePopUp: false,
};

export const spaceDetailsSlice = createSlice({
  name: "spaceDetails",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.

    setCreatorName(state, action) {
      state.spaceDetailsData.creatorName = action.payload;
    },
    setCreatorProfileImage(state, action) {
      state.spaceDetailsData.creatorProfileImage = action.payload;
    },
    setLayout(state, action) {
      state.spaceDetailsData.layout = action.payload;
    },
    setLightColor(state, action) {
      state.spaceDetailsData.lightColor = action.payload;
    },
    setDarkColor(state, action) {
      state.spaceDetailsData.darkColor = action.payload;
    },
    setColorCombo(state, action) {
      state.spaceDetailsData.colorCombo = action.payload;
    },
    setParaFont(state, action) {
      state.spaceDetailsData.paraFont = action.payload;
    },
    setHeadingFont(state, action) {
      state.spaceDetailsData.headerFont = action.payload;
    },
    setSiteFont(state, action) {
      state.spaceDetailsData.siteFont = action.payload;
    },
    setDescription(state, action) {
      state.spaceDetailsData.description = action.payload;
    },
    setButtonName(state, action) {
      state.spaceDetailsData.buttonName = action.payload;
    },
    setSpaceDetails(state, action) {
      state.spaceDetailsData = action.payload;
    },
    setSpaceDetailsStatus(state, action) {
      state.status = action.payload;
    },
    setSpaceDetailsLoading(state, action) {
      state.loading = action.payload;
    },
    setSpaceDetailsLiveSite(state, action) {
      state.liveSitePopUp = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getUserSpaceDetailsData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.spaceDetailsData = payload;
        state.loading = false;
      })
      .addCase(getUserSpaceDetailsData.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getUserSpaceDetailsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export const {
  setLayout,
  setParaFont,
  setSiteFont,
  setDarkColor,
  setLightColor,
  setColorCombo,
  setButtonName,
  setHeadingFont,
  setCreatorName,
  setDescription,
  setSpaceDetails,
  setSpaceDetailsStatus,
  setSpaceDetailsLoading,
  setCreatorProfileImage,
  setSpaceDetailsLiveSite,
  RecipeSliceToLimitActions,
} = spaceDetailsSlice.actions;

export default spaceDetailsSlice.reducer;
