/* eslint-disable no-unused-vars */
import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
} from "@mui/material";
import Fade from "@mui/material/Fade";
import StarIcon from "@mui/icons-material/Star";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Link } from "react-router-dom";
import AppIcon from "../assets/images/AppIcon.png";
import { setNotificationDashboard } from "../redux/slice/userSlice";
import EmptyImage from "../assets/images/CreatorScreen/emptyImage.png";
import {
  MuiDialogContainer,
  NotificationListSubheader,
  NotificationStack,
} from "./Styles";

export default function NotificationModal() {
  const dispatch = useDispatch();
  const userSlice = useSelector((state) => state.user);

  const handleNotification = () => {
    dispatch(setNotificationDashboard(!userSlice.notificationIcon));
  };
  const icon = (
    <Paper sx={{ m: 1 }} elevation={1}>
      <Box component="svg" sx={{ width: 100, height: 100, background: "none" }}>
        <Box
          component="polygon"
          sx={{
            // height: "30px",
            fill: (theme) => theme.palette.common.white,
            stroke: (theme) => theme.palette.divider,
            strokeWidth: 1,
          }}
          points="0,210 60,80, 110,180"
        />
      </Box>
    </Paper>
  );

  const notification = 0;
  return (
    <Box sx={{ position: "absolute", right: 0, top: 50, width: "100%" }}>
      <Fade
        in={userSlice.notificationIcon}
        sx={{
          background: "none",
          position: "absolute",
          right: -25,
          top: -85,
          zIndex: 1,
          // border: "none",
          boxShadow: "none",
          height: "50px",
        }}
      >
        {icon}
      </Fade>

      {/* <MuiTooltipOnClick> */}
      <MuiDialogContainer
        open={userSlice.notificationIcon}
        keepMounted
        onClose={handleNotification}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle
          sx={{ borderBottom: "1px solid #cecece", padding: "8px 12px" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2">Notification</Typography>
            <IconButton
              onClick={handleNotification}
              sx={{ width: "9px", height: "14px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: "317px", padding: 0 }}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              padding: 0,
            }}
            subheader={
              notification ? (
                <NotificationListSubheader>
                  <Typography
                    variant="body2"
                    color="primary"
                    sx={{ fontSize: "12px", ml: "10px" }}
                  >
                    02 Unread
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#7C7B79"
                    sx={{ display: "flex", fontSize: "12px", mr: "10px" }}
                  >
                    <IconButton
                      sx={{ width: "12px", height: "12px", mr: "3px" }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{ width: "12px", height: "12px" }}
                      />
                    </IconButton>
                    Mark all as read
                  </Typography>
                </NotificationListSubheader>
              ) : (
                ""
              )
            }
          >
            {!notification ? (
              <NotificationStack direction="column" px={3}>
                <img
                  src={EmptyImage}
                  alt=""
                  style={{
                    height: "70px",
                    width: "60px",
                    objectFit: "fill",
                    paddingBottom: "10px",
                  }}
                />
                <Typography variant="body2">No notification</Typography>
                <Typography variant="body3" my={2}>
                  Customize notification preferences and stay updated!
                </Typography>
                <Link
                  to="/creator/settings"
                  style={{ textDecoration: "none" }}
                  onClick={handleNotification}
                >
                  <Typography variant="body3" color="primary">
                    Update Settings
                  </Typography>
                </Link>
              </NotificationStack>
            ) : (
              [1, 2, 3].map(() => (
                <>
                  <ListItem
                    sx={{
                      padding: "12px 0",
                      borderBottom: "1px solid #cecece",
                      paddingRight: "10px",
                    }}
                  >
                    <ListItemAvatar sx={{ marginLeft: "30px" }}>
                      <Avatar src={AppIcon} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "12px !important",
                            display: "flex",
                            marginBottom: "7px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "12px !important",
                              fontWeight: 600,
                            }}
                          >
                            Marcus D{" "}
                          </Typography>
                          started following you.
                        </Typography>
                      }
                      secondary={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px !important",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Today 06 Mar 2023
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px !important",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            5:40 pm
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      padding: "12px 0",
                      borderBottom: "1px solid #cecece",
                      paddingRight: "10px",
                    }}
                  >
                    <ListItemAvatar sx={{ marginLeft: "30px" }}>
                      <Avatar sx={{ background: "#FFD772" }}>
                        <StarIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "12px !important",
                            display: "flex",
                            lineHeight: 1,
                            marginBottom: "7px",
                          }}
                        >
                          A New Version of Klynk app is ready to download in
                          Playstore
                        </Typography>
                      }
                      secondary={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "12px !important",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Today 06 Mar 2023
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px !important",
                            }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            5:40 pm
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </>
              ))
            )}
          </List>
        </DialogContent>
      </MuiDialogContainer>
      {/* </MuiTooltipOnClick> */}
    </Box>
  );
}
