import { createSlice } from "@reduxjs/toolkit";
import { getUserDetail } from "../actions/userAsyncActions";

const userDetails =
  window.localStorage.getItem("userInfo") !== []
    ? JSON.parse(window.localStorage.getItem("userInfo"))
    : null;

const isFollow =
  window.localStorage.getItem("isFollow") !== null
    ? JSON.parse(window.localStorage.getItem("isFollow"))
    : true;
// async thunk initial state
const initialState = {
  userDetails,
  status: "idle",
  error: null,
  loading: false,
  userLoggedIn: false,
  profileDashboard: false,
  notificationIcon: false,
  feedbackIcon: false,
  isFollow,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDisplayName(state, action) {
      state.userDetails.displayName = action.payload;
    },
    setEmailId(state, action) {
      state.userDetails.email = action.payload;
    },
    setPassword(state, action) {
      state.userDetails.password = action.payload;
    },
    setProfileImage(state, action) {
      state.userDetails.photoImage = action.payload;
    },
    setImportFromRecipeURL(state, action) {
      state.userDetails.importRecipesURL = action.payload;
    },
    setParsedFromOutsideURL(state, action) {
      state.userDetails.parseFromOutsideURL = action.payload;
    },
    setWebURL(state, action) {
      state.userDetails.webURL = action.payload;
    },
    setFirstName(state, action) {
      state.userDetails.firstName = action.payload;
    },
    setLastName(state, action) {
      state.userDetails.lastName = action.payload;
    },
    setCountry(state, action) {
      state.userDetails.country = action.payload;
    },
    // creator followers emails.
    setSubscriberEmails(state, action) {
      state.userDetails.subscriberEmails.push(action.payload);
    },
    // collapse of profile dashboard
    setProfileDashboard(state, action) {
      state.profileDashboard = action.payload;
    },
    // collapse of notification icon
    setNotificationDashboard(state, action) {
      state.notificationIcon = action.payload;
    },
    setFeedbackDashboard(state, action) {
      state.feedbackIcon = action.payload;
    },
    // needs to format like this. refactor.? next time.
    setUserData(state, action) {
      state.userDetails = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setIsFollow(state, action) {
      state.isFollow = action.payload;
      localStorage.setItem("isFollow", JSON.stringify(action.payload));
    },
    setLogOut(state) {
      window.localStorage.removeItem("userInfo");
      window.localStorage.removeItem("userSpaceDetails");
      state.userDetails = null;
      state.loading = false;
      state.error = null;
      state.userLoggedIn = false;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getUserDetail.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.userDetails = payload;
        state.userLoggedIn = true;
      })
      .addCase(getUserDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setId,
  // user personal details
  setUserData,
  setUserName,
  setDisplayName,
  setEmailId,
  setPassword,
  setProfileImage,
  setImportFromRecipeURL,
  setParsedFromOutsideURL,
  setWebURL,
  setFirstName,
  setLastName,
  setCountry,
  // profile dashboard collapse
  setProfileDashboard,
  // notification icon collapse
  setNotificationDashboard,
  // subscribers emails
  setFeedbackDashboard,
  setSubscriberEmails,
  setLoading,
  setLogOut,
  setIsFollow,
} = userSlice.actions;

export default userSlice.reducer;
