import { createSlice } from "@reduxjs/toolkit";
import { getRecipeDataFromDB } from "../../actions/recipeActions/userAsyncRecipeData";

// async thunk initial state
const initialState = {
  recipeDraftsData: [],
  status: "idle",
  error: null,
};

export const recipeSlice = createSlice({
  name: "recipeDrafts",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setRecipeDraftsData(state, action) {
      state.recipeDraftsData = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getRecipeDataFromDB.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.recipeDraftsData = payload;
      })
      .addCase(getRecipeDataFromDB.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRecipeDataFromDB.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setRecipeDraftsData } = recipeSlice.actions;

export default recipeSlice.reducer;
