/* eslint-disable no-throw-literal */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-catch */
import firebase, { FirebaseApi, storageRef } from "../../services/firebaseConfig";
import { CRUDoperations } from "../CRUD/crud";
import recipes from "../Recipes/recipes";

const user = {
  register: async (email, password) => {
    try {
      // Registering user with firebase authentication, no need for checking account exists. firebase authentication will do that.
      const register = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
      const user = await register.user;
      //  firebase authentication only creates user with email and password, if you want to store other  details we need to create a collection and store there
      // created a collection with registered user.id (for easy access later) and storing additional information there
      await user.sendEmailVerification();
      await FirebaseApi.user.doc(user.uid).set({
        id: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoImage: user.photoURL,
        importRecipesURL: "",
        webURL: "",
        firstName: "",
        lastName: "",
        country: "",
        parseFromOutsideURL: false,
        subscriberEmails: [],
        spaceName: {},
        collection: {
          draftedRecipes: [],
          publishedRecipes: [],
          draftedArticles: [],
          publishedArticles: [],
        },
        spaceNameID: "",
        collectionID: "",
        memberShipLevels: [],
        providerId: user.providerData[0].providerId,
      });
      // after storing the additional info, getting that and returning
      const doc = await FirebaseApi.user.doc(user.uid).get();
      return doc.data();
    } catch (error) {
      throw error;
    }
  },
  login: async (email, password) => {
    try {
      // firebase auth will check for the credentials
      const userCredentials = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      // after getting userCredentials we are getting additional info with userId from firestore collection and returing that details

      const doc = await FirebaseApi.user.doc(userCredentials?.user?.uid).get();
      return {
        ...doc.data(),
        providerData: userCredentials.user?.providerData,
      };
      // return User.user
    } catch (err) {
      throw err;
    }
  },
  logout: async () => {
    try {
      await firebase.auth().signOut();
      return "logout successful";
    } catch (error) {
      throw error;
    }
  },
  authCheck: async () => {
    try {
      await firebase.auth().onAuthStateChanged((user) => {
        console.log({ cloudServicesUser: user });
        if (user) {
          return user;
        }

        return "";
      });
    } catch (err) {
      throw err;
    }
  },
  currentUser: async () => {
    const user = await firebase.auth().currentUser;
    return user;
  },
  googleSignIn: async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);
      return result;
    } catch (error) {
      throw error;
    }
  },
  googleCustomSignIn: async ({ idToken, accessToken, profile }) => {
    try {
      const credentials = await firebase.auth.GoogleAuthProvider.credential(
        idToken,
        accessToken
      );

      const result = await firebase.auth().signInWithCredential(credentials);

      const checkForUserData = await FirebaseApi.user
        .doc(result.user?.uid)
        .get();
      const userDataInFirestore = checkForUserData.data();

      console.log({ result, userDataInFirestore });

      if (!userDataInFirestore) {
        const addToCollection = await FirebaseApi.user
          .doc(result.user?.uid)
          .set({
            id: result.user?.uid,
            email: profile.email,
            username: profile.name,
            firstname: profile.given_name,
            lastname: profile.family_name,
            profileImage: profile.picture,
            followers: 0,
            following: 0,
            likedRecipes: 0,
            cookbooksCount: 0,
            profileImageName: "",
            userDetails: false,
          });
      }
      const doc = await FirebaseApi.user.doc(result.user?.uid).get();

      return {
        ...doc.data(),
        providerData: result.user?.providerData,
      };
    } catch (error) {
      throw error;
    }
  },
  facebookCustomSignIn: async (accessToken) => {
    try {
      const credentials = await firebase.auth.FacebookAuthProvider.credential(
        accessToken
      );

      const result = await firebase.auth().signInWithCredential(credentials);

      const checkForUserData = await FirebaseApi.user
        .doc(result.user?.uid)
        .get();
      const userDataInFirestore = checkForUserData.data();

      if (!userDataInFirestore) {
        const addToCollection = await FirebaseApi.user
          .doc(result.user?.uid)
          .set({
            id: result.user?.uid,
            email: result.additionalUserInfo?.profile?.email,
            username: result.additionalUserInfo?.profile?.name,
            firstname: result.additionalUserInfo?.profile?.first_name,
            lastname: result.additionalUserInfo?.profile?.last_name,
            profileImage:
              result.additionalUserInfo?.profile?.picture?.data?.url,
            provider: result.additionalUserInfo?.providerId,
            followers: 0,
            following: 0,
            likedRecipes: 0,
            cookbooksCount: 0,
            profileImageName: "",
            userDetails: false,
          });
      }
      const doc = await FirebaseApi.user.doc(result.user?.uid).get();
      return {
        ...doc.data(),
        providerData: result.user?.providerData,
      };
    } catch (error) {
      throw error;
    }
  },
  getOtpPhoneSignIn: async (phoneNumber, recaptchaVerifier) => {
    try {
      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      const verification = await phoneProvider.verifyPhoneNumber(
        phoneNumber,
        recaptchaVerifier
      );
      return verification;
    } catch (error) {
      throw error;
    }
  },
  validateOtpAndSignIn: async (verificationId, otp) => {
    try {
      const credential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otp
      );
      const result = await firebase.auth().signInWithCredential(credential);

      const checkForUserData = await FirebaseApi.user
        .doc(result.user?.uid)
        .get();
      const userDataInFirestore = checkForUserData.data();

      if (!userDataInFirestore) {
        const addToCollection = await FirebaseApi.user
          .doc(result.user?.uid)
          .set({
            id: result.user?.uid,
            email: result.additionalUserInfo?.profile?.email || "",
            username: result.additionalUserInfo?.profile?.name || "",
            firstname: result.additionalUserInfo?.profile?.first_name || "",
            lastname: result.additionalUserInfo?.profile?.last_name || "",
            profileImage:
              result.additionalUserInfo?.profile?.picture?.data?.url || "",
            provider: result.additionalUserInfo?.providerId || "",
            phoneNumber: result.user?.phoneNumber,
            providerData: result.user?.providerData,
            followers: 0,
            following: 0,
            likedRecipes: 0,
            cookbooksCount: 0,
            profileImageName: "",
            userDetails: false,
          });
      }
      const doc = await FirebaseApi.user.doc(result.user?.uid).get();
      return {
        ...doc.data(),
        providerData: result.user?.providerData,
      };
    } catch (error) {
      throw error;
    }
  },
  linkPhoneToAccount: async (verificationId, otp) => {
    try {
      const credentials = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otp
      );
      user.linkAccount(credentials);
    } catch (error) {
      throw error;
    }
  },
  linkFaceBookToAccount: async (accessToken) => {
    try {
      const credentials = await firebase.auth.FacebookAuthProvider.credential(
        accessToken
      );
      user.linkAccount(credentials);
    } catch (error) {
      throw error;
    }
  },
  linkAccount: async (credentials) => {
    try {
      const user = await firebase.auth().currentUser;
      const result = await user.linkWithCredential(credentials);
      console.log(result);
      return "Linked Successfully";
    } catch (error) {
      throw error;
    }
  },
  unLinkAccount: async (providerId) => {
    try {
      const user = await firebase.auth().currentUser;
      const unlink = await user.unlink(providerId);
      return "unlinked Successfully";
    } catch (error) {
      throw error;
    }
  },
  forgotPassword: async (email) => {
    try {
      const result = await firebase.auth().sendPasswordResetEmail(email);
      console.log({ passwordRestResult: result });
      return result;
    } catch (error) {
      throw error;
    }
  },
  searchUsers: async (options) => {
    try {
      // this is search operation for users
      // first we are getting all users from firestore collection
      const snapshot = await CRUDoperations.search("user", options);

      return snapshot;
      // and checking for the search keyword with the name of the individual user
      // and returing the found result back
      // const result = await snapshot.docs.filter(doc => (doc.data()).username === keyword)
      // console.log(result[0]?.data(), result)
      // return result
    } catch (err) {
      throw err;
    }
  },
  searchRecipes: async (options) => {
    try {
      // should search recipes according to user preferences
      const snapshot = await CRUDoperations.search("recipe", options);
      console.log(snapshot);
      return snapshot;
    } catch (err) {
      throw err;
    }
  },
  searchIngredients: async (options) => {
    try {
      const ingredientSearch = await CRUDoperations.search(
        "ingredient",
        options
      );
      return ingredientSearch;
    } catch (err) {
      throw err;
    }
  },
  follow: async (toFollowId, userId) => {
    try {
      // getting "following" collection from firebase
      const snapshot = await FirebaseApi.following.get();

      // getting current user all details
      const getUserDetails = await FirebaseApi.user.doc(userId).get();

      // getting details of the user to whom the current user wants to follow
      const getFollowingUserDetails = await FirebaseApi.user
        .doc(toFollowId)
        .get();

      // checking if current user has any data in the following collection
      const userTable = snapshot.docs.filter(
        (doc) => doc.data().userId === userId
      );

      // if the current user has any data in following table means he already followed someone previously
      //  we are creating a new entry
      if (userTable.length === 0) {
        // adding data to "following" collection with fields { userId,followingId}
        const result = await FirebaseApi.following.add({
          userId,
          followingId: toFollowId,
        });

        // after adding the new entry we need to update the following count of the "user" and followers count of the "follwed user"
        const updateUserDetails = await FirebaseApi.user.doc(userId).update({
          ...getUserDetails.data(),
          following: getUserDetails.data().following + 1,
        });
        const updateFollowedUserDetails = await FirebaseApi.user
          .doc(toFollowId)
          .update({
            ...getFollowingUserDetails.data(),
            followers: getFollowingUserDetails.data().followers + 1,
          });
        return "Followed Successfully";
      }
      // else user have data already

      const alreadyFollowed = userTable.filter(
        (item) => item.data().followingId === toFollowId
      );

      if (alreadyFollowed.length > 0) {
        return "Already Followed";
      }

      const result = await FirebaseApi.following.add({
        userId,
        followingId: toFollowId,
      });
      const updateUserDetails = await FirebaseApi.user
        .doc(userId)
        .update({ following: getUserDetails.data().following + 1 });
      const updateFollowedUserDetails = await FirebaseApi.user
        .doc(toFollowId)
        .update({ followers: getFollowingUserDetails.data().followers + 1 });
      return "Followed Successfully";

      // userTable.forEach(async (item) => {

      //     // checking if currentUser already followed to that id
      //     if (item.data().followingId == toFollowId) {
      //         throw 'already followed'

      //     }
      //     // else we are creating a new entry
      //     else {
      //         const result = await FirebaseApi.following.add({ userId, followingId: toFollowId })
      //         const updateUserDetails = await FirebaseApi.user.doc(userId).update({ following: getUserDetails.data().following + 1 })
      //         const updateFollowedUserDetails = await FirebaseApi.user.doc(toFollowId).update({ followers: getFollowingUserDetails.data().followers + 1 })
      //     }
      // })
    } catch (err) {
      throw err;
    }
  },
  unFollow: async (toUnFollowId, userId) => {
    try {
      // getting "following" collection from firebase
      const snapshot = await FirebaseApi.following.get();

      // getting current user all details
      const getUserDetails = await FirebaseApi.user.doc(userId).get();

      // getting details of the user to whom the current user wants to follow
      const getFollowingUserDetails = await FirebaseApi.user
        .doc(toUnFollowId)
        .get();

      // checking if current user has any data in the following collection
      const userTable = snapshot.docs.filter(
        (doc) =>
          doc.data().userId === userId &&
          doc.data().followingId === toUnFollowId
      );
      console.log(userTable[0].id);
      console.log(userTable[0].data());
      const unFollowing = await FirebaseApi.following
        .doc(userTable[0].id)
        .delete();
      const updateUserDetails = await FirebaseApi.user.doc(userId).update({
        ...getUserDetails.data(),
        following: getUserDetails.data().following - 1,
      });
      const updateFollowedUserDetails = await FirebaseApi.user
        .doc(toUnFollowId)
        .update({
          ...getFollowingUserDetails.data(),
          followers: getFollowingUserDetails.data().followers - 1,
        });
      return "unFollowed Successfully";
    } catch (err) {
      throw err;
    }
  },
  getFollowing: async (userId) => {
    try {
      // getting the all data of the "following" collection
      const snapshot = await FirebaseApi.following.get();

      // geting the data of current user
      const userTable = await snapshot.docs.filter(
        (doc) => doc.data().userId === userId
      );

      // if user has already followed someone
      // proceed
      if (userTable.length > 0) {
        // var followingUsers = []

        const followingUsers = userTable.map(async (user) => {
          // getting info of each of the following user data
          const userDetail = await FirebaseApi.user
            .doc(user.data().followingId)
            .get();

          // if there is data for the particular following id we add that to followingUsers
          // console.log(userDetail.data())

          return userDetail?.data();
        });
        // console.log(followingUsers)
        // console.log(await Promise.all(followingUsers))
        return await Promise.all(followingUsers);

        // return followingUsers
      }
      // else should return not following

      console.log("not following");
    } catch (err) {
      throw err;
    }
  },
  getFollowers: async (userId) => {
    try {
      // getting data "following" Collection
      const snapshot = await FirebaseApi.following.get();
      // now from the same data we are searching for entries which have followingId === userId and get the id of who is following

      const userTable = await snapshot.docs.filter(
        (doc) => doc.data().followingId === userId
      );

      // if userTable.length is 0 that means there are no followers yet
      // else
      if (userTable.length > 0) {
        // var followingUsers = []
        const followingUsers = userTable.map(async (user) => {
          // console.log(user.data())
          // now getting each of the user data and adding it to followingUsers
          const userDetail = await FirebaseApi.user
            .doc(user.data().userId)
            .get();

          // console.log(userDetail.data())
          const checkingUserFollowedCurrentUser = await snapshot.docs.filter(
            (doc) =>
              doc.data().userId === userId &&
              doc.data().followingId === userDetail.data().id
          );
          // console.log(checkingUserFollowedCurrentUser)
          if (checkingUserFollowedCurrentUser.length > 0) {
            return { followingThisUser: true, ...userDetail?.data() };
          }

          return { followingThisUser: false, ...userDetail?.data() };

          // return userDetail?.data()
        });
        return await Promise.all(followingUsers);
      }
    } catch (err) {
      throw err;
    }
  },
  getUserWithId: async (userId, Id) => {
    try {
      const UserDetails = await FirebaseApi.user.doc(Id).get();

      const followingData = await FirebaseApi.following.get();
      // console.log(followingData)

      const currentUserFollowStatus = followingData.docs.filter(
        (doc) => doc.data().userId === userId && doc.data().followingId === Id
      );
      // console.log(currentUserFollowStatus[0].data())

      if (currentUserFollowStatus.length > 0) {
        return { followingThisUser: true, ...UserDetails.data() };
      }

      return { followingThisUser: false, ...UserDetails.data() };
    } catch (err) {
      throw err;
    }
  },
  addCollection: async (userId, name, type, list = []) => {
    try {
      const addingNewUserGroup = await FirebaseApi.collections.add({
        userId,
        name,
        type,
        list,
      });
      console.log(addingNewUserGroup);
    } catch (err) {
      throw err;
    }
  },
  addUserGroups: async (userId, name, type = "userGroup") => {
    try {
      const addingNewUserGroup = await user.addCollection(userId, name, type);
      console.log(addingNewUserGroup);
    } catch (err) {
      throw err;
    }
  },
  addCookBooks: async (userId, name, type = "recipeCookBook") => {
    try {
      // getting user Details
      const getUserDetails = await FirebaseApi.user.doc(userId).get();

      const addingNewCookbook = await user.addCollection(userId, name, type);
      // we need to update cookbooksCount in user info
      const updateUserDetails = await FirebaseApi.user
        .doc(userId)
        .update({ cookbooksCount: getUserDetails.data().cookbooksCount + 1 });
      return "Created Cookbook successfully";
    } catch (err) {
      throw err;
    }
  },
  cookBooks: async (userId) => {
    try {
      // const getUserDetails = await FirebaseApi.user.doc(userId).get()
      console.log("in collection");
      // getting data from "cookBooks" collection for currentUser
      const userData = await FirebaseApi.collections
        .where("userId", "==", `${userId}`)
        .where("type", "==", "recipeCookBook")
        .get();

      const data = userData.docs.map((doc) => doc.data());

      // if currentUser have any cookbooks we return them
      return data;
    } catch (err) {
      throw err;
    }
  },
  groups: async (userId) => {
    try {
      // getting data from "cookBooks" collection for currentUser
      const userData = await FirebaseApi.collections
        .where("userId", "==", `${userId}`)
        .where("type", "==", "userGroup")
        .get();

      const data = userData.docs.map((doc) => doc.data());

      // if currentUser have any cookbooks we return them
      return data;
    } catch (err) {
      throw err;
    }
  },
  addRecipeToCookBook: async (userId, cookBookName, recipeDetails) => {
    try {
      // searching cookbooks for the currentUser
      const userData = await FirebaseApi.collections
        .where("userId", "==", `${userId}`)
        .where("name", "==", `${cookBookName}`)
        .get();

      const collection = userData.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      const cookBookDoc = collection[0];

      const searchingForExistence = cookBookDoc.data?.list.filter(
        (recipe) => recipe.id === recipeDetails.id
      );

      if (searchingForExistence.length > 0) {
        throw `Already added the recipe to ${cookBookName}`;
      } else {
        const addingRecipeToDoc = cookBookDoc.data?.list.push(recipeDetails);
        console.log(addingRecipeToDoc);
        const updatingCollectionData = await FirebaseApi.collections
          .doc(cookBookDoc.id)
          .update(cookBookDoc.data);
        return "Added successfully";
      }

      // // // const updateCookBook = await FirebaseApi.cookBooks.doc(userId).update({ books: [...userData.data().books,] })
    } catch (err) {
      throw err;
    }
  },
  deleteRecipeFromCookBook: async (userId, cookBookName, recipeId) => {
    try {
      // searching cookbooks for the currentUser
      const userData = await FirebaseApi.collections
        .where("userId", "==", `${userId}`)
        .where("cookBookName", "==", `${cookBookName}`)
        .get();

      const collection = userData.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      const cookBookDoc = collection[0];

      const removingFromCollection = cookBookDoc.data?.recipes.filter(
        (recipe) => recipe.id !== recipeId
      );

      const updateCollection = await FirebaseApi.collections
        .doc(cookBookDoc.id)
        .update({ recipes: removingFromCollection });
      return "deleted successfully";
    } catch (err) {
      throw err;
    }
  },
  getAllRecipes: async () => {
    try {
      const recipesData = await recipes.get();

      return recipesData;
    } catch (err) {
      throw err;
    }
  },
  getRecipeWithId: async (id) => {
    try {
      const recipe = await recipes.getRecipeWithId(id);
      return recipe;
    } catch (err) {
      throw err;
    }
  },
  getUserRecipes: async (userName) => {
    try {
      const userRecipes = await recipes.search({
        keyword: [["recipe.author.name", "==", `${userName}`]],
      });

      return userRecipes;
    } catch (err) {
      throw err;
    }
  },
  getURL: async (file) => {
    try {
      const url = await storageRef
        .child(`images/${file.name}`)
        .getDownloadURL();
      console.log(url);
      return url;
    } catch (err) {
      throw err;
    }
  },
  uploadLogs: async (file, name) => {
    try {
      const logsRef = storageRef.child(`logs/${name}`);
      await logsRef.put(file);
    } catch (error) {
      throw error;
    }
  },
  testUploadFromRN: async (file) => {
    try {
      console.log(file);
      const logsRef = storageRef.child(`logs/${file.name}`);
      await logsRef.putString(file.data, "base64");
    } catch (error) {
      throw error;
    }
  },
  uploadFile: async (file) => {
    try {
      // const drawImageInCanvas = await imageCompression.drawImageInCanvas(compressedFile)

      // console.log(compressedImage)
      const profileImagesRef = storageRef.child(`images/${file.name}`); // 'images/JaneDoe.jpg'
      const task = await profileImagesRef.put(file);
    } catch (err) {
      throw err;
    }
  },
  deleteFile: async () => {
    try {
      const result = await storageRef.child("images/wp4924054.jpg").delete();
      console.log(result);
      // return 'deleted'
    } catch (err) {
      throw err;
    }
  },
  showAllFiles: async () => {
    try {
      const list = await storageRef.child("images").listAll();
      // const result = await list.listAll()
      await list.prefixes.forEach((folderRef) => {
        console.log(folderRef);
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      await list.items.forEach((itemRef) => {
        console.log(itemRef);
        // All the items under listRef.
      });
      console.log(list);
    } catch (err) {
      throw err;
    }
  },
  updateUserDetails: async (details, id) => {
    try {
      const getPresentDetails = await FirebaseApi.user.doc(id).get();

      await FirebaseApi.user
        .doc(id)
        .update({ ...getPresentDetails.data(), ...details, userDetails: true });
      return "updated";
    } catch (error) {
      throw error;
    }
  },
  getUserDetails: async (id) => {
    try {
      const details = await FirebaseApi.user.doc(id).get();
      // console.log(details.data());
      return { id, ...details.data() };
    } catch (error) {
      throw error;
    }
  },
  minderResult: async (id, data) => {
    try {
      const allDetails = await FirebaseApi.user.doc(id).get();
      const details = allDetails.data();
      console.log(details);
      let updatedData;
      if (details.minder) {
        const { minder } = details;
        updatedData = {
          likedRecipes: [...minder?.likedRecipes, ...data.likedRecipes],
          disLikedRecipes: [
            ...minder?.disLikedRecipes,
            ...data.disLikedRecipes,
          ],
          superLikedRecipes: [
            ...minder?.superLikedRecipes,
            ...data.superLikedRecipes,
          ],
        };
      } else {
        updatedData = { ...data };
      }
      console.log(updatedData);
      const updateUserDetails = await FirebaseApi.user
        .doc(id)
        .update({ minder: updatedData });
      return "updated";
    } catch (error) {
      throw error;
    }
  },
};

export default user;
