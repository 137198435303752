import { createSlice } from "@reduxjs/toolkit";

// async thunk initial state
const initialState = {
  open: false,
  type: "info",
  message: "",
  timeout: 5000,
  vertical: "right",
  horizontal: "left",
};

export const snackbarSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    displayMessage(state, action) {
      state.type = action.payload.type || state.type;
      state.message = action.payload.message;
      state.open = true;
      state.vertical = action.payload.vertical || state.vertical;
      state.horizontal = action.payload.horizontal || state.horizontal;
    },
    clearMessage(state) {
      state.open = false;
    },
  },
});

export const { displayMessage, clearMessage } = snackbarSlice.actions;
export default snackbarSlice.reducer;
