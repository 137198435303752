import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirebaseApi } from "../../../services/firebaseConfig";

export const getRecipeDataFromDB = createAsyncThunk(
  "user/getRecipeDataFromDB",
  async (args) => {
    try {
      const { creatorID } = args;
      const data = await FirebaseApi.testRecipes
        .where("creatorId", "==", creatorID)
        .where("collection", "==", "drafts")
        // .orderBy("createdAt")
        .get();
      const DBdata = await data.docs.map((item) => ({
        id: item.id,
        // error will be here
        // createdAt: formatFirebaseDate(item?.createdAt),
        subscriptionLevel: "Free",
        ...item.data(),
      }));
      // console.log(DBdata, "async recipe drafts data");
      return DBdata;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      }
      return error.message;
    }
  }
);
