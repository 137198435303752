import { createSlice } from "@reduxjs/toolkit";
import { getRecipeDataFromRecipeCollection } from "../../actions/recipeActions/userAsyncRecipeColection";

// async thunk initial state
const initialState = {
  recipeDataRecipeCollection: [],
  status: "idle",
  isHide: false,
  error: null,
};

export const recipeCollection = createSlice({
  name: "recipesCollection",
  initialState,

  reducers: {
    // needs to format like this. refactor.? next time.
    setRecipeDataRecipeCollection(state, action) {
      state.recipeDataRecipeCollection = action.payload;
    },
    setIsHide(state, action) {
      state.isHide = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(
        getRecipeDataFromRecipeCollection.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.recipeDataRecipeCollection = payload;
        }
      )
      .addCase(getRecipeDataFromRecipeCollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRecipeDataFromRecipeCollection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setRecipeDataRecipeCollection, setIsHide } =
  recipeCollection.actions;

export default recipeCollection.reducer;
