/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { setLoading, setLogOut } from "../redux/slice/userSlice";
import { auth } from "../services/firebaseConfig";
import { useNotification } from "../hooks/useSnackBar";

const MuiButton = styled(Button)({
  padding: "12px 35px",
  width: "fit-content",
  height: 34,
  background: "#EF4329",
  marginTop: 2,
  color: "#FFFFFF",
  borderRadius: "3px",
  ":hover": {
    background: "#EF4329",
  },
});

export default function MuiLogoutModal(props) {
  const { open, handleClose } = props;
  const { displaySnackBar } = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);

  const userLogout = async () => {
    // setLoading(true);
    dispatch(setLoading(true));
    await auth()
      .signOut()
      .then(() => {
        // setLoading(false);
        dispatch(setLogOut());
        displaySnackBar({
          message: "You're now logged out of Klynk. Don't be a stranger",
          type: "success",
          vertical: "top",
          horizontal: "center",
        });
        navigate("/login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          sx={{
            textAlign: "center",
            width: 410,
            height: 236,
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ float: "right", borderRadius: "50%" }}
          >
            <CloseIcon
              sx={{
                width: "13px",
                height: "13px",
                color: "#000",
                fontWeight: 700,
              }}
            />
          </IconButton>
          <Grid p={3}>
            <Typography variant="subHeading1">
              Are you sure you want to logout of Klynk?
            </Typography>
          </Grid>

          <MuiButton onClick={userLogout}>
            <Typography variant="h5" color="#FFFFFF" textTransform="capitalize">
              Yes
            </Typography>
          </MuiButton>
          <Typography
            onClick={handleClose}
            mt={2}
            variant="subtitle1"
            sx={{ color: "#7C7B79", cursor: "pointer" }}
          >
            No, I’ve changed my mind
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
