/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import Fade from "@mui/material/Fade";
import KlynkCircleLogo from "../../assets/images/CreatorScreen/KlynkCircleLogo.svg";

import { FeedbackBox, GridFeedback } from "./Style";
import Header from "./Header";
import useFeedback from "./useFeedback";
import FeedbackMainContent from "./FeedbackMainContent";

export default function Feedback() {
  const {
    day,
    month,
    reset,
    feedbackIcon,
    bugBtnHandler,
    handleFeedback,
    onUserHandler,
    userClick,
    onUserClose,
    feedbackFormik,
    featureFormik,
    reportBugFormik,
    divRef,
    addInterestDataToFirebase,
  } = useFeedback();

  const icon = (
    <Paper sx={{ m: 1 }} elevation={1}>
      <Box component="svg" sx={{ width: 115, height: 60, background: "none" }}>
        <Box
          component="polygon"
          sx={{
            height: "30px",
            fill: () => "#fff",
            stroke: (theme) => theme.palette.divider,
            strokeWidth: 1,
            background: "#ffff",
          }}
          points="0,130 50,30, 110,140"
        />
      </Box>
    </Paper>
  );

  return (
    <Box
      sx={{ position: "absolute", right: 0, top: 16, backgroundColor: "none" }}
    >
      <Grid>
        {" "}
        <Fade
          in={feedbackIcon}
          sx={{
            background: "none",
            padding: 0,
            boxShadow: "none",
            height: "50px",
          }}
        >
          {icon}
        </Fade>
      </Grid>

      <FeedbackBox
        open={feedbackIcon}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleFeedback}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <Header
          onClose={onUserClose}
          featureFormik={featureFormik}
          feedbackFormik={feedbackFormik}
          reportBugFormik={reportBugFormik}
        />
        <Box>
          <Typography variant="subtitle1" textAlign="center" mt={1}>
            Today, {month} {day}
          </Typography>
          <Grid container alignItems="center" width="100%" p={2}>
            <Grid item xs={1} ml={-1}>
              <img
                src={KlynkCircleLogo}
                alt="klynk logo"
                style={{ width: "32px", height: "32px" }}
              />
            </Grid>
            <GridFeedback item xs={9.4} ml={2} mt={-1}>
              <Typography variant="subtitle1">
                Hello, what would you like to do today?
              </Typography>
            </GridFeedback>
          </Grid>
          <FeedbackMainContent
            ref={divRef}
            data={reset}
            onClose={onUserClose}
            userClick={userClick}
            bugBtnHandler={bugBtnHandler}
            onUserHandler={onUserHandler}
            featureFormik={featureFormik}
            feedbackFormik={feedbackFormik}
            reportBugFormik={reportBugFormik}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        </Box>
      </FeedbackBox>
    </Box>
  );
}
