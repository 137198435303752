import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import DashboardAppBar from "../components/DashboardAppBar";
import CreatorNavbar from "../pages/CreatorsPage/Common/CreatorNavbar";
import DownloadCSV from "../utils/downloadCSV";
import DummyPage from "../services/dummyImage";

// basic imports
// import { lazyRetry } from "services/lazyRetry";
// import CreatorsPage from "pages/CreatorsPage/Dashboard";
// import ContentCreation from "pages/CreatorsPage/ContentCreation";
// import MemberShipLevels from "pages/CreatorsPage/Membership/MembershipLevel";
// import Editor from "pages/CreatorsPage/Editor/Editor";
// import ChefInfo from "pages/CreatorsPage/Common/ChefInfo";
// import ManageLevelsScreen from "pages/CreatorsPage/Membership/ManageMembershipLevels";
// import Settings from "pages/CreatorsPage/Settings/Settings";
// import ManualRCT from "pages/CreatorsPage/Recipe/manualRCT";
// import CreateArticle from "pages/CreatorsPage/Article/index";
// import ResetPassword from "pages/Auth/ForgotPassword";
// import LogInPage from "pages/Auth/LogInPage";
// import SignUpPage from "pages/Auth/SignUpPage";
// import VerifyEmail from "pages/Auth/VerifyEmail";
// import ProtectedRoutes from "./ProtectedRoutes";
// import ProfileCreation from "pages/ProfileSteps/ProfileCreationStepper";
// import Billings from "pages/CreatorsPage/Billings";

// lazy loads pages
const CreatorsPage = lazy(() =>
  import(/* webpackChunkName: 'Dashboard' */ "../pages/CreatorsPage/Dashboard")
);
const Editor = lazy(() =>
  import(
    /* webpackChunkName: 'SiteEditor' */ "../pages/CreatorsPage/Editor/Editor"
  )
);
const ChefInfo = lazy(() =>
  import(/* webpackChunkName: 'ChefPage' */ "../pages/CreatorsPage/ChefPage")
);
const Settings = lazy(() =>
  import(
    /* webpackChunkName: 'SettingsPage' */ "../pages/CreatorsPage/Settings/Settings"
  )
);
const CreateArticle = lazy(() =>
  import(
    /* webpackChunkName: 'ArticleLayout' */ "../pages/CreatorsPage/Article/index"
  )
);
const ForgotPassword = lazy(() =>
  import(
    /* webpackChunkName: 'ForgotPassword' */ "../pages/Auth/ForgotPassword"
  )
);
const ResetPassword = lazy(() =>
  import(/* webpackChunkName: 'ResetPassword' */ "../pages/Auth/ResetPassword")
);
const LogInPage = lazy(() =>
  import(/* webpackChunkName: 'LoginInPage' */ "../pages/Auth/LogInPage")
);
const SignUpPage = lazy(() =>
  import(/* webpackChunkName: 'SignInPage' */ "../pages/Auth/SignUpPage")
);
const VerifyEmail = lazy(() =>
  import(/* webpackChunkName: 'VerifyEmail' */ "../pages/Auth/VerifyEmail")
);
const Billings = lazy(() =>
  import(/* webpackChunkName: 'Billings' */ "../pages/CreatorsPage/Billings")
);
const SuccessEmail = lazy(() =>
  import(
    /* webpackChunkName: 'SuccessEmail' */ "../pages/Auth/SuccessForgetPassword"
  )
);
const SuccessPasswordUpdate = lazy(() =>
  import(
    /* webpackChunkName: 'SuccessPassword' */ "../pages/Auth/SuccessPasswordUpdate"
  )
);
const Landing = lazy(() =>
  import(/* webpackChunkName: 'LandingPage' */ "../pages/LandingPage/Landing")
);
// const Feedback = lazy(() =>
//   import(/* webpackChunkName: 'Feedback' */ "../pages/FeedbackPage/Feedback")
// );
const ProtectedRoutes = lazy(() =>
  import(/* webpackChunkName: 'ProtectedRoutes' */ "./ProtectedRoutes")
);
const MemberShipLevels = lazy(() =>
  import("../pages/CreatorsPage/Membership/MembershipLevel")
);
const ManageLevelsScreen = lazy(() =>
  import("../pages/CreatorsPage/Membership/ManageMembershipLevels")
);
const ProfileCreation = lazy(() =>
  import(
    /* webpackChunkName: 'ProfileCreation' */ "../pages/ProfileSteps/ProfileCreationStepper"
  )
);
const ContentCreation = lazy(() =>
  import(
    /* webpackChunkName: 'contentCreation' */ "../pages/CreatorsPage/ContentCreation"
  )
);
// const NewCreateRecipe = lazy(() =>
//   import("../pages/CreatorsPage/Recipe/CreateRecipe")
// );
const CreateRecipe = lazy(() =>
  import(
    /* webpackChunkName: 'RecipeLayout' */ "../pages/CreatorsPage/NewRecipeLayout"
  )
);

const ArticleDisplay = lazy(() =>
  import(
    /* webpackChunkName: 'ArticleLayout' */ "../pages/DisplayScreen/ArticleDisplay/ArticleDisplay"
  )
);
// const CreateRecipePro = lazy(() =>
//   import("../pages/CreatorsPage/Recipe/CreateRecipePro")
// );
const Tos = lazy(() =>
  import(
    /* webpackChunkName: 'TermsAndServices' */ "../pages/CreatorsPage/Common/Tos"
  )
);
const Privacy = lazy(() =>
  import(
    /* webpackChunkName: 'PrivacyAndPolicy' */ "../pages/CreatorsPage/Common/Privacy"
  )
);
const ErrorPage = lazy(() =>
  import(/* webpackChunkName: 'ErrorPage' */ "../components/ErrorPage")
);
const RecipeDisplay = lazy(() =>
  import(
    /* webpackChunkName: 'RecipePreviewAndPublishState' */ "../pages/DisplayScreen/RecipeDisplay/RecipeDisplay"
  )
);

function CreatorRoutes() {
  return (
    <Routes>
      <Route element={<LogInPage />} path="/login" exact />
      <Route element={<SignUpPage />} path="/signUp" exact />
      <Route element={<VerifyEmail />} path="/verifyEmail" exact />
      <Route element={<ForgotPassword />} path="/forgotPassword" exact />
      <Route element={<ResetPassword />} path="/resetPassword" exact />
      <Route
        element={<ChefInfo data="login" />}
        path="/chefInfo/:keyID"
        exact
      />
      <Route element={<SuccessEmail />} path="/successEmail" exact />
      {/* need to remove above line after clarification */}
      <Route element={<ArticleDisplay />} path="/articleDisplay/:id" exact />
      <Route element={<RecipeDisplay />} path="/recipeDisplay/:id" exact />
      <Route
        element={<SuccessPasswordUpdate />}
        path="/successPassword"
        exact
      />
      <Route element={<DownloadCSV />} path="/download" exact />
      <Route element={<ErrorPage />} path="*" />
      <Route element={<ProtectedRoutes />}>
        <Route element={<DashboardAppBar />}>
          {/* side navbar access only to dashboard, content creation, settings */}
          <Route element={<CreatorNavbar />}>
            <Route element={<CreatorsPage />} path="/" />
            <Route element={<Settings />} path="/creator/settings" exact />
            <Route
              element={<ContentCreation />}
              path="/creator/content-creation"
              exact
            />
          </Route>
          <Route element={<CreateArticle />} path="/createArticle" exact />
          <Route element={<CreateArticle />} path="/createArticle/:id" exact />
          <Route element={<CreateRecipe />} path="/createRecipe" exact />
          <Route element={<CreateRecipe />} path="/createRecipe/:id" exact />
          <Route
            element={<ArticleDisplay />}
            path="/articleDisplay/:id/edit"
            exact
          />
          <Route
            element={<RecipeDisplay />}
            path="/recipeDisplay/:id/edit"
            exact
          />
          <Route element={<Editor />} path="/creator/editor" exact />
        </Route>
        <Route element={<ProfileCreation />} path="/onboarding" exact />

        <Route element={<Billings />} path="/creator/billings" exact />
        <Route element={<MemberShipLevels />} path="/memberShipLevels" exact />
        <Route element={<ManageLevelsScreen />} path="/manageMember" exact />

        <Route element={<Tos />} path="/terms-and-services" exact />
        <Route element={<Privacy />} path="/privacy-policy" exact />
      </Route>
      <Route element={<Landing />} path="/landingPage" exact />
      <Route element={<DummyPage />} path="/dummyPage" exact />
    </Routes>
  );
}

export default CreatorRoutes;
