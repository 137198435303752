import { createSlice } from "@reduxjs/toolkit";
// import { getRecipeDataSevenDays } from "../actions/recipeLastSevenDays";

// async thunk initial state
const initialState = {
  recipeDataSevenDays: [],
  status: "idle",
  error: null,
};

export const recipeLastSevenDays = createSlice({
  name: "recipesSevenDaysData",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setRecipeDataSevenDays(state, action) {
      state.recipeDataSevenDays = action.payload;
    },
  },
  // async thunk
  // eslint-disable-next-line no-unused-vars
  extraReducers: (builder) => {
    // builder
    //   // needs to add authentication slices for register and login and userDetails as well.
    //   .addCase(getRecipeDataSevenDays.fulfilled, (state, { payload }) => {
    //     state.status = "success";
    //     state.recipeDataSevenDays = payload;
    //   })
    //   .addCase(getRecipeDataSevenDays.pending, (state) => {
    //     state.status = "loading";
    //   })
    //   .addCase(getRecipeDataSevenDays.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message;
    //   });
  },
});

export const { setRecipeDataSevenDays } = recipeLastSevenDays.actions;

export default recipeLastSevenDays.reducer;
