/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
import densities from "./spiceDensities";

const unitConverter = {
  converter: (density, ingredient) => {
    switch (ingredient.unit) {
      case "gram":
        const volume = Number(ingredient.quantity) / density;
        return volume;
      case "g":
        const volumeG = Number(ingredient.quantity) / density;
        return volumeG;
      case "teaspoon":
        return unitConverter.unitVolumeConverter(
          Number(ingredient.quantity),
          "teaspoon",
          "ml"
        );
      case "tablespoon":
        return unitConverter.unitVolumeConverter(
          Number(ingredient.quantity),
          "tablespoon",
          "ml"
        );
    }
  },
  conversion: (spice, name, ingredient) => {
    switch (spice) {
      case "salt":
        return unitConverter.converter(densities.salt, ingredient);
      case "chilli powder":
        return unitConverter.converter(densities.chilli, ingredient);
      case "turmeric":
        return unitConverter.converter(densities.turmeric, ingredient);
      case "corainder":
        return unitConverter.converter(densities.coriander, ingredient);
      case "jeera":
      case "cumin":
        return unitConverter.converter(densities.cumin, ingredient);
      case "cardamom":
        return unitConverter.converter(densities.cardamon, ingredient);
      case "fennel":
        return unitConverter.converter(densities.fennel, ingredient);
      case "fenugreek":
        return unitConverter.converter(densities.fenugreek, ingredient);
      case "clove":
        return unitConverter.converter(densities.clove, ingredient);
    }
  },
  unitMassConverter: (value, convertingFrom, convertingTo) => {
    const values = {
      g: 0,
      gram: 0,
      lb: 1,
      oz: 2,
      mg: 3,
      kg: 4,
      teaspoon: 5,
      tablespoon: 6,
      cup: 7,
    };
    const MassArray = [
      [
        1, 0.002204623, 0.035273967999999641942, 1000, 0.00100000017152649984,
        0.24, 0.06666666666666667, 128,
      ],
      [453.592, 1, 16, 453592, 0.4535924, 92.02669618, 30.67556539, 0.5],
      [28.34953, 0.0625, 1, 28349.55, 0.02834953, 6, 2, 4.5],
      [0.001, 0, 0, 1, 1000000, 0.0002, 0.000059, 0.004226753],
      [
        1000, 2.204625, 35.2739619, 1000000, 1, 202.8841362, 67.6280454,
        4.226752838,
      ],
      [5.69, 0.010866412, 0.166667, 5000, 0.004928922, 1, 0.333333, 0.02083333],
      [17.07, 0.032599236, 0.5, 15, 0.014786765, 3, 1, 0.0625],
      [220, 0.5216, 8, 236588.2375, 0.2016, 48, 16, 1],
    ];

    return value * MassArray[values[convertingFrom]][values[convertingTo]];
  },
  unitVolumeConverter: (value, convertingFrom, convertingTo) => {
    const volumeUnits = { ml: 0, L: 1, teaspoon: 2, tablespoon: 3, cup: 4 };
    const VolumeArray = [
      [1, 0.001, 0.2028843, 0.06762807813295484, 0.004226753],
      [1000, 1, 202.8843, 67.628, 4.226753],
      [4.92892, 0.00492892, 1, 0.3333334, 0.02083333],
      [14.7868, 0.0147868, 3, 1, 0.0625],
      [236.588, 0.2365883, 48, 16, 1],
    ];

    return (
      value *
      VolumeArray[volumeUnits[convertingFrom]][volumeUnits[convertingTo]]
    );
  },
};

export default unitConverter;
