import { createSlice } from "@reduxjs/toolkit";
import { getUserLiveSiteSpaceDetailsData } from "../../actions/spaceActions/userAsyncLiveSiteSpaceDetails";

// async thunk initial state
const initialState = {
  spaceLiveSiteDetailsData: [],
  status: "idle",
  error: null,
};

export const spaceDetailsLiveSiteSlice = createSlice({
  name: "spaceDetailsLiveSite",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setSpaceLiveSiteDetailsData(state, action) {
      state.spaceLiveSiteDetailsData = action.payload;
    },
    setSpaceLiveSiteDetailsStatus(state, action) {
      state.status = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(
        getUserLiveSiteSpaceDetailsData.fulfilled,
        (state, { payload }) => {
          state.status = "success";
          state.spaceLiveSiteDetailsData = payload;
        }
      )
      .addCase(getUserLiveSiteSpaceDetailsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserLiveSiteSpaceDetailsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setSpaceLiveSiteDetailsData, setSpaceLiveSiteDetailsStatus } =
  spaceDetailsLiveSiteSlice.actions;

export default spaceDetailsLiveSiteSlice.reducer;
