import { createSlice } from "@reduxjs/toolkit";

// async thunk initial state
const initialState = {
  subscriberData: [],
};

export const subscriberSlice = createSlice({
  name: "subscribers",
  initialState,
  reducers: {
    setSubscriberData(state, action) {
      state.subscriberData = action.payload;
    },
  },
  // async thunk
  extraReducers: () => {},
});

export const { setSubscriberData } = subscriberSlice.actions;

export default subscriberSlice.reducer;
