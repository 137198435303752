import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirebaseApi } from "../../../services/firebaseConfig";

export const getArticlesDataToLimitTo10 = createAsyncThunk(
  "user/getArticlesDataToLimitTo10",
  async (args) => {
    try {
      const { creatorID } = args;
      const data = await FirebaseApi.articles
        .where("creatorId", "==", creatorID)
        .limit(10)
        .get();
      const DBdata = data.docs.map((item) => ({
        id: item.id,
        ...item.data(),
      }));
      return DBdata;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      }
      return error.message;
    }
  }
);
