import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirebaseApi } from "../../../services/firebaseConfig";

export const getUserLiveSiteSpaceDetailsData = createAsyncThunk(
  "user/getUserLiveSiteSpaceDetailsData",
  // eslint-disable-next-line consistent-return
  async (args) => {
    try {
      const { creatorId } = args;
      const data = await FirebaseApi.creatorUILayouts
        .where("creatorId", "==", creatorId)
        .get();
      const spaceData = { ...data.data(), id: data.id };
      // window.localStorage.setItem(
      //   "userSpaceDetails",
      //   JSON.stringify(spaceData)
      // );
      return spaceData;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      }
      return error.message;
    }
  }
);
