import { styled, Dialog, ListSubheader, Stack } from "@mui/material";

export const MuiDialogContainer = styled(Dialog)({
  height: "470px",
  marginRight: "16px",
  "& .MuiDialog-container": {
    display: "flex",
    position: "absolute",
    right: 0,
    top: 16,
    padding: 0,
    width: "320px",
  },
  "& .MuiBackdrop-root": {
    background: "none",
  },
  "& .MuiPaper-root": {
    margin: 0,
    width: "100%",
    padding: 0,
    border: "1px solid #cecece",
    boxShadow:
      "0px 27px 11px rgba(0, 0, 0, 0.05), 0px 22px 29px rgba(101, 101, 101, 0.26)",
    "& .MuiDialog-paper": {
      margin: 0,
      width: "100%",
      padding: 0,
      boxShadow: "none",
    },
  },
});

export const NotificationListSubheader = styled(ListSubheader)({
  lineHeight: 0,
  padding: "4px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
export const NotificationStack = styled(Stack)({
  height: "317px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});
