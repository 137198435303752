import React from "react";
import { AppBar, Badge, Box, Grid, IconButton } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import Klynk from "../assets/images/mainKlynkImage.png";
import {
  setFeedbackDashboard,
  setNotificationDashboard,
} from "../redux/slice/userSlice";
import NotificationModal from "./NotificationModal";
import Feedback from "../pages/FeedbackPage/Feedback";
// import Feedback from "./Feedback";

function DashboardAppBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSlice = useSelector((state) => state.user);

  const handleNotification = () => {
    dispatch(setNotificationDashboard(!userSlice.notificationIcon));
  };

  const handleFeedback = () => {
    dispatch(setFeedbackDashboard(!userSlice.feedbackIcon));
  };

  const notification = 0;

  return (
    <>
      <Box sx={{ flexGrow: 1, pb: 7 }}>
        <AppBar
          position="fixed"
          color="info"
          sx={{
            padding: "10px 20px",
            boxShadow: 0,
            borderBottom: "1px solid #cecece",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Grid item onClick={() => navigate("/")}>
              <img
                src={Klynk}
                alt=""
                style={{ width: "80px", height: "32px", cursor: "pointer" }}
              />
            </Grid>
            <Grid item>
              <Grid container columnGap={2}>
                <Grid
                  item
                  onClick={handleFeedback}
                  sx={{ cursor: "pointer", zIndex: 10 }}
                >
                  <IconButton
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "4px",
                    }}
                  >
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  onClick={handleNotification}
                  sx={{ cursor: "pointer", zIndex: 10 }}
                >
                  <IconButton
                    sx={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "4px",
                    }}
                  >
                    <Badge color="primary" variant={!notification ? "" : "dot"}>
                      <NotificationsNoneIcon />
                    </Badge>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <NotificationModal />
            <Feedback />
          </Grid>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
}

export default DashboardAppBar;
