import { useDispatch } from "react-redux";
import { displayMessage, clearMessage } from "../redux/slice/snackBarSlice";

export const useNotification = () => {
  const dispatch = useDispatch();

  const displaySnackBar = (notification) => {
    dispatch(displayMessage(notification));
  };

  const clearSnackBar = () => {
    dispatch(clearMessage());
  };

  return { displaySnackBar, clearSnackBar };
};
