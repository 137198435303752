import { createSlice } from "@reduxjs/toolkit";
import { getRecipeDataToLimit } from "../../actions/recipeActions/userAsyncRecipeSliceToLimit";

// async thunk initial state
const initialState = {
  recipeDataToLimit: [],
  status: "idle",
  error: null,
};

export const recipeSliceToLimit = createSlice({
  name: "recipesToLimit",
  initialState,

  reducers: {
    // needs to format like this. refactor.? next time.
    setRecipeDataLimit(state, action) {
      state.recipeDataToLimit = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getRecipeDataToLimit.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.recipeDataToLimit = payload;
      })
      .addCase(getRecipeDataToLimit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRecipeDataToLimit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setRecipeDataLimit, setIsHide } = recipeSliceToLimit.actions;

export default recipeSliceToLimit.reducer;
