/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import { BugButton, GridInput } from "./Style";
import BugInput from "./BugInput";

export default function BugFixing(props) {
  const userData = useSelector((state) => state.user.userDetails);
  const scrollRef = useRef();
  const { bugBtnHandler, data, formik, addInterestDataToFirebase, onClose } =
    props;
  const { values, setFieldValue, submitForm } = formik;

  const onBugClick = (Bug) => {
    bugBtnHandler(Bug);
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <Box>
      <Grid container columnSpacing={1.5} p={1} mt={1} width="100%">
        <GridInput item xs={9.1}>
          <Typography variant="subtitle1">
            I would like to Report a bug
          </Typography>
          <Grid
            display="flex"
            justifyContent="center"
            flexDirection="column"
            width="100%"
            height="90%"
            p={1}
            backgroundColor="#FFFFFF"
          >
            <Grid>
              <Typography variant="subtitle1" sx={{ wordBreak: "break-word" }}>
                Can you please tell us where you came across this bug?
              </Typography>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <BugButton
                  variant="outlined"
                  color="secondary"
                  className={data === "site" ? "active" : null}
                  onClick={() => onBugClick("site")}
                >
                  Site Editor
                </BugButton>
                <BugButton
                  variant="outlined"
                  color="secondary"
                  className={data === "home" ? "active" : null}
                  onClick={() => onBugClick("home")}
                >
                  Home
                </BugButton>
                <BugButton
                  variant="outlined"
                  color="secondary"
                  className={data === "content" ? "active" : null}
                  onClick={() => onBugClick("content")}
                >
                  Content creation
                </BugButton>
                <BugButton
                  variant="outlined"
                  color="secondary"
                  className={data === "recipe" ? "active" : null}
                  onClick={() => onBugClick("recipe")}
                >
                  Recipe creation tool
                </BugButton>
                <BugButton
                  variant="outlined"
                  color="secondary"
                  className={data === "setting" ? "active" : null}
                  onClick={() => onBugClick("setting")}
                >
                  Settings
                </BugButton>
              </Grid>
            </Grid>
          </Grid>
        </GridInput>
        <Grid item xs={1}>
          <img
            src={userData?.photoImage}
            alt="Img"
            style={{
              width: "32px",
              height: "32px",
              position: "inherit",
              borderRadius: "50% ",
            }}
          />
        </Grid>
      </Grid>
      <div ref={scrollRef}>
        {data === "site" && (
          <BugInput
            values={values}
            onClose={onClose}
            title="site Editor"
            submitForm={submitForm}
            setFieldValue={setFieldValue}
            bugBtnHandler={bugBtnHandler}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
        {data === "home" && (
          <BugInput
            onClose={onClose}
            title="home"
            values={values}
            submitForm={submitForm}
            setFieldValue={setFieldValue}
            bugBtnHandler={bugBtnHandler}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
        {data === "content" && (
          <BugInput
            onClose={onClose}
            values={values}
            submitForm={submitForm}
            title="content creation"
            setFieldValue={setFieldValue}
            bugBtnHandler={bugBtnHandler}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
        {data === "recipe" && (
          <BugInput
            onClose={onClose}
            values={values}
            submitForm={submitForm}
            title="Recipe creation tool"
            setFieldValue={setFieldValue}
            bugBtnHandler={bugBtnHandler}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
        {data === "setting" && (
          <BugInput
            onClose={onClose}
            title="setting"
            values={values}
            submitForm={submitForm}
            setFieldValue={setFieldValue}
            bugBtnHandler={bugBtnHandler}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
      </div>
    </Box>
  );
}
