/* eslint-disable no-throw-literal */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
import { CRUDoperations } from "../CRUD/crud";

const pantry = {
  create: async (userId, category, data) => {
    console.log(data);
    try {
      const getPantryData = await pantry.read();
      const nameExists = getPantryData.filter((item) => item.name === category);

      const pantryItem = nameExists[0];
      if (nameExists.length > 0) {
        const dataExistInList = pantryItem.list.filter(
          (item) => item.ingredient.name === data.ingredient.name
        );
        console.log(dataExistInList);
        if (dataExistInList.length > 0) {
          throw "Item Already In Pantry";
        }
        // else {

        //     const updateDoc = pantry.update(pantryItem.id, { ...pantryItem, list: [...pantryItem.list, data] })
        //     console.log({ ...pantryItem, list: [...pantryItem.list, data] })
        //     return updateDoc
        // }
      } else {
        const result = await CRUDoperations.create("collections", {
          userId,
          name: category,
          list: [data],
          type: "pantry",
        });

        return result;
      }
    } catch (err) {
      throw err;
    }
  },
  read: async (userId) => {
    try {
      const Data = await CRUDoperations.read("collections", {
        keyword: [
          ["userId", "==", `${userId}`],
          ["type", "==", "pantry"],
        ],
      });
      console.log(Data);
      return Data;
    } catch (err) {
      throw err;
    }
  },
  update: async (docId, updatedData) => {
    try {
      const updating = await CRUDoperations.update(
        "collections",
        docId,
        updatedData
      );
      return updating;
    } catch (err) {
      throw err;
    }
  },
  delete: async (docId) => {
    try {
      const deleting = await CRUDoperations.delete("collections", docId);
      return deleting;
    } catch (err) {
      throw err;
    }
  },
  search: async (options) => {
    try {
      const searchResult = await CRUDoperations.search("collections", options);
      return searchResult;
    } catch (err) {
      throw err;
    }
  },
};

export default pantry;
