/* eslint-disable import/no-relative-packages */
import React, { Suspense } from "react";
import { CreatorAllRoutes } from "./routes";
// import MainComponentSkeleton from "./components/MainComponentSkeleton";
import SnackbarNotification from "./components/MuiSnackbar";
import "./App.css";
import MyErrorBoundary from "./services/ErrorBoundary";
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import TagManager from "react-gtm-module";
import useSubdomains from "./hooks/useSubdomains";
// eslint-disable-next-line import/order

const tagManagerArgs = {
  gtmId: "GTM-P4HDX9B",
};
TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: "pageview",
  });
  const domains = useSubdomains();

  return (
    <MyErrorBoundary>
      {/* <Suspense fallback={<MainComponentSkeleton />}> */}
      <Suspense fallback="">
        <CreatorAllRoutes domains={domains} />
        <SnackbarNotification />
      </Suspense>
    </MyErrorBoundary>
  );
}

export default App;
