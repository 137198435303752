const densities = {
  salt: 2.2,
  chilli: 0.5,
  coriander: 0.4,
  turmeric: 1.3,
  jeera: 0.4,
  cumin: 0.4,
  cardamon: 0.4,
  fennel: 0.4,
  fenugreek: 0.8,
  clove: 0.4,
};

export default densities;
