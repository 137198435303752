import React from "react";
import styled from "@emotion/styled";
import { MobileStepper } from "@mui/material";

export const MobileSteppers = styled((props) => <MobileStepper {...props} />)(
  () => ({
    padding: 0,
    position: "static",
    borderRadius: "4px",
    "& .MuiMobileStepper-progress": {
      width: "54vw",
      background: "#e2e2e2",
      borderRadius: "4px",
    },
    "& .MuiLinearProgress-bar": {
      borderRadius: "4px",
      background: "#ef4329",
    },
  })
);
