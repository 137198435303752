/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
/* eslint-disable no-shadow */
import { FirebaseApi } from "../../services/firebaseConfig";
import { CRUDoperations } from "../CRUD/crud";

const recipes = {
  get: async () => {
    try {
      const gettingRecipes = await FirebaseApi.recipe.limit(15).get();

      const recipes = gettingRecipes.docs.map((recipe) => ({
        ...recipe.data(),
        id: recipe.id,
      }));

      return recipes;
    } catch (err) {
      const error = { message: err.message };
      throw error;
    }
  },
  getRecipeWithId: async (recipeId) => {
    try {
      // getting specific recipe from "recipe" collection
      const result = await FirebaseApi.recipe.doc(recipeId).get();

      // if there is data , return it
      if (result.data()) {
        return { id: recipeId, ...result.data() };
      }
    } catch (err) {
      const error = { message: err.message };
      throw error;
    }
  },
  search: async (options) => {
    try {
      const snapshot = await CRUDoperations.search("recipe", options);
      return snapshot;
    } catch (error) {
      throw error;
    }
  },
  customSearch: async (options) => {
    try {
      const result = await CRUDoperations.suggestSearch("recipe", options);
      return result;
    } catch (error) {
      throw error;
    }
  },
};

export default recipes;
