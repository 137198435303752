import { useEffect, useState } from "react";

const useSubdomains = () => {
  // eslint-disable-next-line no-unused-vars
  const [domains, _setDomains] = useState(false);
  const setDomains = () => {
    if (
      window.location.hostname.split(".")[1] === "localhost" ||
      window.location.hostname.split(".")[2] === "dev"
    ) {
      _setDomains(true);
    }
  };

  useEffect(() => {
    setDomains();
  }, []);
  return domains;
};

export default useSubdomains;
