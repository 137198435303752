import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useNotification } from "../hooks/useSnackBar";

function SnackbarNotification() {
  const notification = useSelector((state) => state.notification);
  const { clearSnackBar } = useNotification();
  const [vertical, setVertical] = useState(notification.vertical);
  const [horizontal, setHorizontal] = useState(notification.horizontal);

  useEffect(() => {
    setVertical(notification.vertical);
    setHorizontal(notification.horizontal);
  }, [notification.vertical, notification.horizontal, notification.timeout]);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    clearSnackBar();
  };

  return (
    <Snackbar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert severity={notification.type}>{notification.message}</Alert>
    </Snackbar>
  );
}

export default SnackbarNotification;
