/* eslint-disable prettier/prettier */
import user from './User/user'
import actions from './Actions/actions'
import courses from './Courses/courses'
import cuisines from './Cuisines/cuisines'
import diets from './Diets/diets'
import ingredients from './Ingredients/ingredients'
import tools from './Tools/tools'
import pantry from './Pantry/pantry'
import shoppingCart from './ShoppingCart/shoppingCart'
import mealPlan from './MealPlan/mealPlan'
import recipes from './Recipes/recipes'
import unitConverter from './Utils/UnitConverter/unitConverter'
import vision from './Vision/vision'





const CloudServices = {
    user,
    mealPlan,
    pantry,
    recipes,
    shoppingCart,
    unitConverter,
    actions,
    courses,
    cuisines,
    diets,
    ingredients,
    tools,
    vision

}




export default CloudServices
export {user,actions,courses,unitConverter,cuisines,diets,ingredients,tools,pantry,shoppingCart,mealPlan,recipes}