import { createSlice } from "@reduxjs/toolkit";
import { getFeaturedRecipeData } from "../actions/userAsyncFeaturedSlice";

// async thunk initial state
const initialState = {
  recipeFeaturedData: [],
  status: "idle",
  error: null,
};

export const featuredRecipeSlice = createSlice({
  name: "featuredRecipes",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setFeaturedData(state, action) {
      state.recipeFeaturedData = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getFeaturedRecipeData.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.recipeFeaturedData = payload;
      })
      .addCase(getFeaturedRecipeData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFeaturedRecipeData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setFeaturedData } = featuredRecipeSlice.actions;

export default featuredRecipeSlice.reducer;
