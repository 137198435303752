import React from "react";
import { Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { setFeedbackDashboard } from "../../redux/slice/userSlice";
import { FeedbackDialogTitle, MainFeedbackBox } from "./Style";

export default function Header(props) {
  const { onClose, featureFormik, feedbackFormik, reportBugFormik } = props;
  const dispatch = useDispatch();
  const userSlice = useSelector((state) => state.user);

  const formikHandler = () => {
    featureFormik.setFieldValue("inputValue", "");
    feedbackFormik.setFieldValue("inputValue", "");
    reportBugFormik.setFieldValue("bugInput", "");
    reportBugFormik.setFieldValue("bugAt", "");
  };

  const handleFeedback = () => {
    formikHandler();
    onClose();
    dispatch(setFeedbackDashboard(!userSlice.feedbackIcon));
  };

  return (
    <FeedbackDialogTitle>
      <MainFeedbackBox>
        <Typography variant="body1">Support</Typography>
        <IconButton
          sx={{ width: "9px", height: "13px" }}
          onClick={handleFeedback}
        >
          <CloseIcon />
        </IconButton>
      </MainFeedbackBox>
    </FeedbackDialogTitle>
  );
}
