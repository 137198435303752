/* eslint-disable no-useless-catch */
/* eslint-disable consistent-return */
import { CRUDoperations } from "../CRUD/crud";

const diets = {
  create: async (value) => {
    try {
      const result = await CRUDoperations.create("diets", value);
      return result;
    } catch (err) {
      console.log(err);
    }
  },
  read: async () => {
    try {
      const Data = await CRUDoperations.read("diets");
      console.log(Data);
      return Data;
    } catch (err) {
      throw err;
    }
  },
  update: async (docId, updatedData) => {
    try {
      const updating = await CRUDoperations.update("diets", docId, updatedData);
      return updating;
    } catch (err) {
      throw err;
    }
  },
  delete: async (docId) => {
    try {
      const deleting = await CRUDoperations.delete("diets", docId);
      return deleting;
    } catch (err) {
      throw err;
    }
  },
};

export default diets;
