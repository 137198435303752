import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
import "firebase/compat/analytics";
import "firebase/compat/storage";
import "firebase/compat/auth";
import "firebase/compat/functions";

// importScripts("https://www.gstatic.com/firebasejs/5.9.4/firebase-app.js");
// importScripts("https://www.gstatic.com/firebasejs/5.9.4/firebase-messaging.js");

const firebaseConfig = {
  apiKey: "AIzaSyCvCN1YIrhHw5vOy7L55P8xfGJ8Yl_PF1U",
  authDomain: "riku-staging.firebaseapp.com",
  databaseURL: "https://riku-staging.firebaseio.com",
  projectId: "riku-staging",
  storageBucket: "gs://riku-staging.appspot.com",
  messagingSenderId: "379205716367",
  appId: "1:379205716367:web:ec86caa8e45d53bda8a3fa",
  measurementId: "G-L3L8L9Z0VD",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
firestore.settings({ ignoreUndefinedProperties: true });

const storage = firebase.storage();
const storageRef = storage.ref();

const FirebaseApi = {
  // articles
  articles: firestore.collection("articles", { merge: true }),
  articleDrafts: firestore.collection("articleDrafts", { merge: true }),

  // user
  user: firestore.collection("/users"),

  // creator related collection
  creatorMemberships: firestore.collection("creatorMemberships"),
  creatorEarnings: firestore.collection("creatorEarnings"),
  creatorUILayouts: firestore.collection("creatorUILayouts"),
  creatorSubscribers: firestore.collection("creatorSubscribers"),
  creatorBillings: firestore.collection("creatorBillings"),
  creatorCollections: firestore.collection("creatorCollections"),

  // Recipe fetching
  recipes: firestore.collection("recipes"),
  testRecipes: firestore.collection("testRecipes", { merge: true }),
  featuredRecipes: firestore.collection("featuredRecipes", { merge: true }),

  // presets
  unit: firestore.collection("units"),
  ingredient: firestore.collection("ingredients"),
  prepStyle: firestore.collection("prepStyles"),
  tools: firestore.collection("tools"),

  // feedback
  feedback: firestore.collection("feedback", { merge: true }),

  // domains
  domainLeads: firestore.collection("domainLeads", { merge: true }),
};

const auth = () => firebase.auth();

const analytics = () => firebase.analytics();

const messaging = () => firebase.messaging();

const functions = () => firebase.functions();

// messaging.setBackgroundMessageHandler((payload) => {
//   const promiseChain = clients
//     .matchAll({
//       type: "window",
//       includeUncontrolled: true,
//     })
//     .then((windowClients) => {
//       // eslint-disable-next-line no-plusplus
//       for (let i = 0; i < windowClients.length; i++) {
//         const windowClient = windowClients[i];
//         windowClient.postMessage(payload);
//       }
//     })
//     .then(() => registration.showNotification("my notification title "));
//   return promiseChain;
// });
// self.addEventListener("notificationclick", (event) => {
//   console.log(event);
// });

export default firebase;
export {
  firestore,
  analytics,
  FirebaseApi,
  storage,
  auth,
  storageRef,
  messaging,
  functions,
};
