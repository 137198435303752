import { createAsyncThunk } from "@reduxjs/toolkit";
import { user } from "../../cloud-services-core";
import { displayMessage } from "../slice/snackBarSlice";

export const getUserDetail = createAsyncThunk(
  "user/getUserDetail",
  async (arg) => {
    try {
      // get user data from store
      const { id } = arg;
      const data = await user.getUserDetails(id);
      window.localStorage.setItem("userInfo", JSON.stringify(data));
      return data;
      // return 0;
    } catch (error) {
      if (error.response && error.response.data.message) {
        displayMessage({ message: error.response.data.message });
        return error.response.data.message;
      }
      return error.message;
    }
  }
);
