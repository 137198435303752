/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography, Avatar, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import KlynkCircleLogo from "../../assets/images/CreatorScreen/KlynkCircleLogo.svg";
import {
  SubmittbBtn,
  TextFieldBug,
  SubmitbBtn,
  UserButton,
  GridInput,
  GridSuccess,
  GridMsg,
} from "./Style";

function InputField({ title, formik, onClose }) {
  const userData = useSelector((state) => state.user.userDetails);

  const scrollRef = useRef();
  const { values, setFieldValue, submitForm } = formik;
  const [successBar, setSuccessBar] = useState(false);
  const [klynkMsg, setKlynkMsg] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [NotInterest, setNotInterest] = useState(false);
  const [interest, setInterest] = useState(false);
  const [input, setInput] = useState(true);

  const onSubmitHandler = () => {
    setTimeout(() => {
      setSuccessBar(true);
    }, 2000);
    setTimeout(() => {
      setKlynkMsg(true);
    }, 3000);

    // setSuccessBar(true);
    setSubmit(true);
    setInput(false);

    // setFieldValue("inputValue", "");
  };
  const notInterest = () => {
    setTimeout(() => {
      setNotInterest(true);
    }, 4000);

    setInterest(false);
    setFieldValue("interested", false);
    submitForm();
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };
  const Interest = () => {
    setTimeout(() => {
      setInterest(true);
    }, 4000);

    setNotInterest(false);
    setFieldValue("interested", true);
    submitForm();
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };
  const onStartNew = () => {
    setSubmit(false);
    setNotInterest(false);
    setInterest(false);
    setFieldValue("inputValue", "");
    onClose();
  };
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setSuccessBar(true);
  //   }, 1000);
  //   return () => clearTimeout(timer);
  // });
  // useEffect(() => {
  //   const timerMsg = setTimeout(() => {
  //     setKlynkMsg(true);
  //   }, 1500);
  //   return () => clearTimeout(timerMsg);
  // });
  // useEffect(() => {
  //   const timerMsg = setTimeout(() => {
  //     setInterest(true);
  //   }, 2000);
  //   return () => clearTimeout(timerMsg);
  // });
  return (
    <Box>
      {input && (
        <Grid
          container
          columnSpacing={1}
          p={1.5}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItem="center"
        >
          <GridInput item xs={9.3}>
            <Typography variant="subtitle1" textAlign="center">
              I would like to give a {title} :
            </Typography>
            <Grid
              display="flex"
              alignItem="center"
              flexDirection="column"
              width="100%"
              mt={2}
            >
              <TextFieldBug
                multiline
                variant="outlined"
                value={values.inputValue}
                inputProps={{
                  minLength: 3,
                  maxLength: 80,
                }}
                onChange={(e) => setFieldValue("inputValue", e.target.value)}
                rows={2}
                placeholder={`Enter your ${title} here`}
                sx={{ width: "100%", background: "#FFFFFF" }}
              />
              <SubmitbBtn
                disabled={values.inputValue.length < 5}
                onClick={() => {
                  submitForm();
                  onSubmitHandler();
                  Interest();
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="#FFFFFF"
                >
                  Submit
                </Typography>
              </SubmitbBtn>
            </Grid>
          </GridInput>
          <Grid item xs={1}>
            <img
              src={userData?.photoImage}
              alt="user"
              style={{
                width: "32px",
                height: "32px",
                position: "inherit",
                borderRadius: "50% ",
              }}
            />
          </Grid>
        </Grid>
      )}
      {submit && (
        <>
          <Grid container columnSpacing={1} p={1.5} width="100%">
            <GridInput item xs={9.3}>
              <Typography variant="subtitle1">{values.inputValue}</Typography>
            </GridInput>
            <Grid item xs={1}>
              <img
                src={userData?.photoImage}
                alt=" "
                style={{
                  width: "32px",
                  height: "32px",
                  position: "inherit",
                  borderRadius: "50% ",
                }}
              />
            </Grid>
          </Grid>
          {successBar ? (
            <GridSuccess>
              <CheckCircleIcon color="success" />
              <Typography variant="subtitle1" color="#34C74C" ml={1}>
                {title} submitted
              </Typography>
            </GridSuccess>
          ) : null}
          {klynkMsg ? (
            <Grid container width="100%" p={1}>
              <Grid item xs={1.5} sx={{ position: "static", top: 0 }}>
                <img
                  src={KlynkCircleLogo}
                  alt=" "
                  style={{ width: "32px", height: "32px" }}
                />
              </Grid>
              <Grid
                item
                xs={9.1}
                sx={{
                  background: "#F1F1F1",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-word" }}
                >
                  Thank you for taking the time to share your {title} with us.
                </Typography>
                {!userData?.interested && (
                  <Grid width="100%" backgroundColor="#FFFFFF" p={1} mt={1.5}>
                    <Typography
                      variant="subtitle1"
                      sx={{ wordBreak: "break-word" }}
                    >
                      Would you like to be a part of our internal research
                      group?
                    </Typography>
                    <Grid display="flex" justifyContent="space-between" pt={1}>
                      <UserButton
                        onClick={() => Interest()}
                        className={interest ? "active" : null}
                      >
                        Yes, I’m Intrested
                      </UserButton>
                      <UserButton
                        onClick={() => notInterest()}
                        className={NotInterest ? "active" : null}
                      >
                        Not right now
                      </UserButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
      <div ref={scrollRef}>
        {NotInterest && (
          <>
            <Grid container width="100%" px={1}>
              <Grid item xs={1.5}>
                <img
                  src={KlynkCircleLogo}
                  alt="klynk logo"
                  style={{ width: "32px", height: "32px" }}
                />
              </Grid>
              <Grid
                item
                xs={9.1}
                sx={{
                  background: "#F1F1F1",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-word" }}
                >
                  If you ever change your mind about joining our Research Group,
                  please do not hesitate to reach out to us. Have a great day!
                </Typography>
              </Grid>
            </Grid>
            <Grid p={2}>
              <Divider
                sx={{ paddingX: "10px", fontSize: "11px", color: "#7C7B79" }}
              >
                Conversation Ended
              </Divider>
              <SubmittbBtn width="75% !important" onClick={() => onStartNew()}>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="#FFFFFF"
                >
                  Start new conversation
                </Typography>
              </SubmittbBtn>
            </Grid>
          </>
        )}
        {interest && (
          <>
            <Grid container width="100%" px={1}>
              <Grid item xs={1.5}>
                <img
                  src={KlynkCircleLogo}
                  alt="klynk logo"
                  style={{ width: "32px", height: "32px" }}
                />
              </Grid>
              <Grid
                item
                xs={9.1}
                sx={{
                  background: "#F1F1F1",
                  height: "80px",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-word" }}
                >
                  Great! We will reach out to you via email. Thank you again for
                  your interest!
                </Typography>
              </Grid>
            </Grid>
            <Grid p={2}>
              <Divider
                sx={{ paddingX: "10px", fontSize: "11px", color: "#7C7B79" }}
              >
                Conversation Ended
              </Divider>
              <SubmittbBtn width="75% !important" onClick={() => onStartNew()}>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="#FFFFFF"
                >
                  Start new conversation
                </Typography>
              </SubmittbBtn>
            </Grid>
          </>
        )}
      </div>
    </Box>
  );
}

export default InputField;
