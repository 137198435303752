import React, { useRef } from "react";
import { Grid, Typography, Box } from "@mui/material";
import BugFixing from "./BugFixing";
import InputField from "./InputField";
import { FeedbackButton, FeedbackMainGrid } from "./Style";

function FeedbackMainContent(props) {
  const scrollRef = useRef(null);
  const {
    data,
    userClick,
    bugBtnHandler,
    onUserHandler,
    feedbackFormik,
    featureFormik,
    reportBugFormik,
    addInterestDataToFirebase,
    onClose,
  } = props;

  const onFeatureClick = (feature) => {
    onUserHandler(feature);
    setTimeout(() => {
      const scroll = scrollRef.current;
      scroll.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  return (
    <Box>
      <FeedbackMainGrid mt={-1}>
        <Typography variant="subtitle1">Please choose one below</Typography>
        <Grid container sx={{ paddingTop: "10px" }} rowSpacing={1}>
          <Grid item xs={12}>
            <FeedbackButton
              variant="outlined"
              className={userClick === "feedback" ? "active" : null}
              color="secondary"
              onClick={() => onFeatureClick("feedback")}
            >
              Give us Feedback!
            </FeedbackButton>
          </Grid>
          <Grid item xs={12}>
            <FeedbackButton
              color="secondary"
              className={userClick === "feature" ? "active" : null}
              variant="outlined"
              onClick={() => onFeatureClick("feature")}
            >
              Request a Feature
            </FeedbackButton>
          </Grid>
          <Grid item xs={12}>
            <FeedbackButton
              color="secondary"
              className={userClick === "bug" ? "active" : null}
              variant="outlined"
              onClick={() => onFeatureClick("bug")}
            >
              Report a Bug
            </FeedbackButton>
          </Grid>
        </Grid>
      </FeedbackMainGrid>
      <div ref={scrollRef}>
        {userClick === "feedback" && (
          <InputField
            onClose={onClose}
            title="Feedback"
            formik={feedbackFormik}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
        {userClick === "feature" && (
          <InputField
            onClose={onClose}
            title="Feature"
            formik={featureFormik}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
        {userClick === "bug" && (
          <BugFixing
            onClose={onClose}
            title="Bug"
            data={data}
            formik={reportBugFormik}
            bugBtnHandler={bugBtnHandler}
            addInterestDataToFirebase={addInterestDataToFirebase}
          />
        )}
      </div>
    </Box>
  );
}

export default FeedbackMainContent;
