import { createSlice } from "@reduxjs/toolkit";
import { getArticlesDataToLimitTo10 } from "../../actions/articleActions/userAsyncArticleToLimit";

// async thunk initial state
const initialState = {
  articleDataToLimitTo10: [],
  status: "idle",
  error: null,
};

export const articleSliceToLimitTo10 = createSlice({
  name: "articleLimitTo10",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setArticleDataLimitTo10(state, action) {
      state.articleDataToLimitTo10 = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getArticlesDataToLimitTo10.fulfilled, (state, { payload }) => {
        // console.log("status fulfilled");
        state.status = "success";
        state.articleDataToLimitTo10 = payload;
      })
      .addCase(getArticlesDataToLimitTo10.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getArticlesDataToLimitTo10.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setArticleDataLimitTo10 } = articleSliceToLimitTo10.actions;

export default articleSliceToLimitTo10.reducer;
