import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirebaseApi } from "../../../services/firebaseConfig";

export const getArticlesDraftsDataFromDB = createAsyncThunk(
  "user/getArticlesDraftsDataFromDB",
  async (args) => {
    try {
      const { creatorID } = args;
      const data = await FirebaseApi.articleDrafts
        .where("creatorId", "==", creatorID)
        .limit(5)
        .get();
      const DBdata = await data.docs.map((item) => ({
        id: item.id,
        subscriptionLevel: "Free",
        ...item.data(),
      }));
      return DBdata;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      }
      return error.message;
    }
  }
);
