import React from "react";
import klynk from "../assets/images/ImageReplace.jpg";

function DummyImage() {
  return (
    <>
      <p>image remove</p>
      <img src={klynk} alt="" />
    </>
  );
}

export default DummyImage;
