/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography, Avatar, Divider } from "@mui/material";
import KlynkCircleLogo from "../../assets/images/CreatorScreen/KlynkCircleLogo.svg";
import {
  SubmittbBtn,
  UserButton,
  SubmitbBtn,
  TextFieldBug,
  GridInput,
  GridSuccess,
  GridMsg,
} from "./Style";

export default function BugInput(props) {
  const userData = useSelector((state) => state.user.userDetails);
  const scrollRef = useRef();
  const {
    title,
    values,
    submitForm,
    bugBtnHandler,
    setFieldValue,
    addInterestDataToFirebase,
    onClose,
  } = props;
  const [successBar, setSuccessBar] = useState(false);
  const [klynkMsg, setKlynkMsg] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [notInterest, setNotInterest] = useState(false);
  const [interest, setInterest] = useState(false);
  const [input, setInput] = useState(true);

  const onSubmitHandler = () => {
    setTimeout(() => {
      setSuccessBar(true);
    }, 2000);
    setTimeout(() => {
      setKlynkMsg(true);
    }, 3000);
    setSubmit(true);

    setInput(false);
  };
  const NonInterest = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
    setTimeout(() => {
      setNotInterest(true);
    }, 4000);
    setInterest(false);
    setFieldValue("interested", false);
  };

  const Interest = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
    setTimeout(() => {
      setInterest(true);
    }, 4000);
    setNotInterest(false);
    setFieldValue("interested", true);
    addInterestDataToFirebase();
  };

  const onStartNew = () => {
    setSubmit(false);
    setNotInterest(false);
    setInterest(false);
    setFieldValue("bugInput", "");
    onClose();
  };

  return (
    <Box>
      {input && (
        <Grid container columnSpacing={1} p={1} width="100%">
          <GridInput item xs={9.1}>
            <Typography variant="subtitle1" sx={{ wordBreak: "break-word" }}>
              I came across this bug in {`"${title}"`}
            </Typography>
            <Grid
              display="flex"
              justifyContent="center"
              flexDirection="column"
              width="100%"
              mt={2}
            >
              <TextFieldBug
                multiline
                variant="outlined"
                type="text"
                value={values.bugInput}
                onChange={(e) => setFieldValue("bugInput", e.target.value)}
                rows={2}
                inputProps={{
                  minLength: 3,
                  maxLength: 80,
                }}
                placeholder="Tell us more about this bug"
                sx={{
                  width: "100%",
                  background: "#FFFFFF",
                  variant: "subtitle2",
                }}
              />
              <SubmitbBtn
                disabled={values.bugInput.length < 5}
                onClick={() => {
                  onSubmitHandler();
                  submitForm();
                  // Interest();
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="#FFFFFF"
                >
                  Submit
                </Typography>
              </SubmitbBtn>
            </Grid>
          </GridInput>
          <Grid item xs={1}>
            <img
              src={userData?.photoImage}
              alt="user"
              style={{
                width: "32px",
                height: "32px",
                position: "inherit",
                borderRadius: "50% ",
              }}
            />
          </Grid>
        </Grid>
      )}
      {submit && (
        <>
          <Grid container columnSpacing={1} p={1.5} mt={-1} width="100%">
            <GridInput item xs={9.2}>
              <Typography variant="subtitle1">{values.bugInput}</Typography>
            </GridInput>
            <Grid item xs={1}>
              <img
                src={userData?.photoImage}
                alt="userImg"
                style={{
                  width: "32px",
                  height: "32px",
                  position: "inherit",
                  borderRadius: "50% ",
                }}
              />
            </Grid>
          </Grid>
          {successBar ? (
            <GridSuccess>
              <CheckCircleIcon color="success" />
              <Typography variant="subtitle1" color="#34C74C" ml={2}>
                Bug reported successfully
              </Typography>
            </GridSuccess>
          ) : null}
          {klynkMsg && (
            <Grid container width="100%" py={2} px={1}>
              <Grid item xs={1.4} mr={0.8} sx={{ position: "static", top: 0 }}>
                <img
                  src={KlynkCircleLogo}
                  alt="klynk logo"
                  style={{ width: "32px", height: "32px" }}
                />
              </Grid>
              <GridMsg item xs={9} mt={-1}>
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-word" }}
                >
                  Thank you for helping us improve our product.
                </Typography>
                {!userData?.interested && (
                  <Grid width="100%" backgroundColor="#FFFFFF" p={1} mt={1.5}>
                    <Typography
                      variant="subtitle1"
                      sx={{ wordBreak: "break-word" }}
                    >
                      Would you like to be a part of our internal research
                      group?
                    </Typography>
                    <Grid display="flex" justifyContent="space-between" pt={1}>
                      <UserButton
                        onClick={() => Interest()}
                        className={interest ? "active" : null}
                      >
                        Yes, I’m Intrested
                      </UserButton>
                      <UserButton
                        onClick={() => NonInterest()}
                        className={notInterest ? "active" : null}
                      >
                        Not right now
                      </UserButton>
                    </Grid>
                  </Grid>
                )}
              </GridMsg>
            </Grid>
          )}
        </>
      )}
      <div ref={scrollRef}>
        {notInterest && (
          <>
            <Grid container width="100%" px={2}>
              <Grid item xs={1.4}>
                <img
                  src={KlynkCircleLogo}
                  alt="klynk logo"
                  style={{ width: "32px", height: "32px" }}
                />
              </Grid>
              <Grid
                item
                xs={9.5}
                sx={{
                  background: "#F1F1F1",
                  height: "105px",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-word" }}
                >
                  If you ever change your mind about joining research group,
                  please do not hesitate to reach out to us. Have a great day!
                </Typography>
              </Grid>
            </Grid>
            <Grid p={2}>
              <Divider
                sx={{ paddingX: "10px", fontSize: "11px", color: "#7C7B79" }}
              >
                Conversation Ended
              </Divider>
              <SubmittbBtn
                width="80% !important"
                onClick={() => {
                  onStartNew();
                  bugBtnHandler("");
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="#FFFFFF"
                >
                  Start new conversation
                </Typography>
              </SubmittbBtn>
            </Grid>
          </>
        )}
        {interest && (
          <>
            <Grid container width="100%" px={1}>
              <Grid item xs={1.5} mr={0.6}>
                <img
                  src={KlynkCircleLogo}
                  alt="klynk logo"
                  style={{ width: "32px", height: "32px" }}
                />
              </Grid>
              <Grid
                item
                xs={9}
                sx={{
                  background: "#F1F1F1",
                  height: "80px",
                  padding: "8px",
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ wordBreak: "break-word" }}
                >
                  We will be reaching out to you via email. Thank you again for
                  your interest!
                </Typography>
              </Grid>
            </Grid>
            <Grid p={2}>
              <Divider
                sx={{ paddingX: "10px", fontSize: "11px", color: "#7C7B79" }}
              >
                Conversation Ended
              </Divider>
              <SubmittbBtn
                width="75% !important"
                onClick={() => {
                  onStartNew();
                  bugBtnHandler("");
                }}
              >
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  color="#FFFFFF"
                >
                  Start new conversation
                </Typography>
              </SubmittbBtn>
            </Grid>
          </>
        )}
      </div>
    </Box>
  );
}
