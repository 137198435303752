import {
  Button,
  styled,
  Dialog,
  TextField,
  Typography,
  Grid,
  DialogTitle,
  Box,
} from "@mui/material";

export const FeedbackButton = styled(Button)({
  width: "100%",
  position: "inherit",
  height: 33,
  margin: "auto",
  padding: "4px",
  color: "#393939",
  borderRadius: "8px",
  textTransform: "none",
  "&.active": {
    color: "#fff !important",
    background: "#EF4823 !important",
    borderStyle: "none",
  },
  "&:hover": {
    color: "#fff !important",
    background: "#EF4823 !important",
    borderStyle: "none",
  },
  "& .MuiButtonBase-root-MuiButton-root": {
    position: "inherit",
  },
});

export const FeedbackBox = styled(Dialog)({
  position: "fixed",
  height: "50vh",
  overflowY: "scroll",
  marginRight: -15,
  "& .MuiDialog-container": {
    position: "fixed",
    right: 50,
    width: 385,
    height: "60vh",
    top: 55,
    "& .MuiDialog-paper": {
      // boxShadow: "none",
      boxShadow:
        "0px 27px 11px rgba(0, 0, 0, 0.05), 0px 22px 29px rgba(101, 101, 101, 0.26)",
      paddingBottom: 25,
      marginTop: 0,
      marginRight: "-25px",
      border: "1px solid #cecece",
    },
  },
  "& .MuiBackdrop-root": {
    background: "none",
    opacity: 0.5,
  },
});

export const BugButton = styled(Button)({
  height: 30,
  padding: "10px",
  borderRadius: "30px",
  fontSize: "10px",
  margin: "3px 5px",
  position: "inherit",
  textTransform: "capitalize",
  "&.active": {
    color: "#fff !important",
    background: "#EF4823 !important",
    borderStyle: "none",
  },
  "&:hover": {
    color: "#fff !important",
    background: "#EF4823 !important",
    borderStyle: "none",
  },
});
export const UserButton = styled(Button)({
  width: "45%",
  height: 30,
  position: "inherit",
  border: "1px solid #393939",
  background: "#FFFFFF;",
  // margin: "auto",
  padding: "2px",
  color: "#7C7B79",
  borderRadius: "30px",
  cursor: "pointer",
  fontSize: "10px",
  textTransform: "capitalize",
  "&.active": {
    color: "#fff !important",
    background: "#EF4823 !important",
    borderStyle: "none",
  },
  "&:hover": {
    color: "#fff !important",
    background: "#EF4823 !important",
    borderStyle: "none",
  },
});
export const SubmitbBtn = styled(Button)({
  width: "100%",
  height: 34,
  border: "1px solid #393939",
  background: "#1C1C1C",
  padding: "5px",
  color: "#FFFFFF",
  margin: " 12px auto",
  borderRadius: "8px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
  "&:disabled": {
    opacity: 0.4,
  },
});
export const SubmittbBtn = styled(Typography)({
  width: "100%",
  height: 34,
  border: "1px solid #393939",
  background: "#1C1C1C",
  padding: "5px",
  color: "#FFFFFF",
  margin: " 12px auto",
  borderRadius: "8px",
  cursor: "pointer",
});

export const TextFieldBug = styled(TextField)({
  ".MuiOutlinedInput-root": {
    fontSize: "16px",
  },
});
export const GridInput = styled(Grid)({
  background: "#F1F1F1",
  marginLeft: "auto",
  padding: "15px",
  borderRadius: "4px",
  wordBreak: "break-word",
});
export const GridFeedback = styled(Grid)({
  background: "#F1F1F1",
  height: "60px",
  padding: "8px",
  borderRadius: "4px",
});
export const GridMsg = styled(Grid)({
  background: "#F1F1F1",
  padding: "10px",
  borderRadius: "4px",
});
export const GridSuccess = styled(Grid)({
  display: "flex",
  background: "#F1F1F1",

  width: "71%",
  margin: " -5px auto auto 15% ",
  padding: "10px",
  borderRadius: "4px",
});
export const FeedbackMainGrid = styled(Grid)({
  width: "225px",
  height: "175px",
  background: "#F1F1F1",
  margin: "0px auto",
  padding: "15px",
  borderRadius: "4px",
});
export const FeedbackDialogTitle = styled(DialogTitle)({
  borderBottom: "1px solid #cecece",
  padding: "8px 12px",
  position: "sticky",
  top: 0,
  width: "100%",
  background: "#fff",
  zIndex: 10,
});
export const MainFeedbackBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#fff",
});
