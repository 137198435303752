import { createSlice } from "@reduxjs/toolkit";
import { getArticlesDataFromDB } from "../../actions/articleActions/userAsyncArticleData";

// async thunk initial state
const initialState = {
  articleData: [],
  status: "idle",
  error: null,
};

export const articleSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setArticlePublished(state, action) {
      state.articleData = action.payload;
    },
    setArticlePublishedStatus(state, action) {
      state.status = action.payload;
    },
    setFilteredArticles(state, action) {
      const { Id } = action.payload;
      state.articleData = state.articleData.filter(
        (article) => article.id !== Id
      );
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      .addCase(getArticlesDataFromDB.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.articleData = payload;
      })
      .addCase(getArticlesDataFromDB.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getArticlesDataFromDB.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setArticlePublished,
  setArticlePublishedStatus,
  setFilteredArticles,
} = articleSlice.actions;

export default articleSlice.reducer;
