import { combineReducers } from "@reduxjs/toolkit";
import {
  // article reducers
  articleReducer,
  articleDraftsReducer,
  articlePaginationReducer,
  articleSliceLimitTo10Reducer,

  // user reducer
  userReducer,

  // recipe reducer
  recipeReducer,
  recipeLimitReducer,
  recipeLimitTo10Reducer,
  recipePaginationReducer,
  recipeCollectionReducer,
  recipeCountLastSevenDays,

  // space bar reducer
  spaceDetailsReducer,
  spaceDetailsLiveSiteReducer,

  // snackbar reducer
  snackbarReducer,

  // featured reducer
  featuredRecipeReducer,

  // subscriber reducer
  subscriberReducer,
} from "../slice";

const rootReducer = combineReducers({
  // article details
  article: articleReducer,
  articleDrafts: articleDraftsReducer,
  articlePagination: articlePaginationReducer,
  articleLimitTo10: articleSliceLimitTo10Reducer,

  // user details
  user: userReducer,

  // recipe details
  recipeDrafts: recipeReducer,
  recipesToLimit: recipeLimitReducer,
  recipeLimitTo10: recipeLimitTo10Reducer,
  lastSevenDays: recipeCountLastSevenDays,
  recipePagination: recipePaginationReducer,
  recipesCollection: recipeCollectionReducer,
  // snackbar details
  notification: snackbarReducer,

  // space details
  spaceDetails: spaceDetailsReducer,
  spaceDetailsLiveSite: spaceDetailsLiveSiteReducer,

  // featured recipe details
  featuredRecipes: featuredRecipeReducer,

  // subscriber details
  subscribers: subscriberReducer,
});

export default rootReducer;
