import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export const ChefInfoNavbar = styled("div")({
  backgroundColor: "#333",
  position: "fixed",
  top: "-50px",
  width: "100%",
  display: "block",
  transition: "top 0.3s",
});

export const GridCreatorList = styled(Grid)({
  width: "100%",
  paddingLeft: "12%",
  paddingRight: "12%",
  paddingTop: 5,
  transition: "none",
});

export const LinkCreatorList = styled(NavLink)({
  display: "flex",
  alignItems: "center",
  color: "#292929",
  padding: "10px 20px",
  textDecoration: "none",
  borderRadius: "5px",
  "&.active": {
    color: "#fff !important",
    background: "#FF8469 !important",
  },
  "&:hover": {
    background: "#F1F1F1",
  },
});
export const LinkCreatorsList = styled(NavLink)({
  display: "flex",
  alignItems: "center",
  color: "#292929",
  padding: "10px 0px 10px 10px",
  textDecoration: "none",
  borderRadius: "5px",
  cursor: "auto",
});

export const LinkCreatorLogout = styled(Grid)({
  display: "flex",
  alignItems: "center",
  color: "#292929",
  padding: "10px 20px",
  cursor: "pointer",
  borderRadius: "5px",
  textDecoration: "none",
  "&:active": {
    color: "#fff !important",
    background: "#FF8469 !important",
  },
  "&:hover": {
    background: "#F1f1f1",
  },
});
export const ComingSoonButton = styled(Typography)({
  // padding: "4px 6px",
  display: "flex",
  alignItems: "center",
  // background: "#FFECE4",
  // borderRadius: "12px",
  marginLeft: "6px",
  textTransform: "capitalize",
});

export const ImageCreatorList = styled("img")({
  width: 20,
  height: 20,
  padding: {
    xs: "auto",
    sm: "none",
    md: "none",
    lg: "none",
  },
});

// live site main page
export const LiveSiteMainPage = styled(Grid)({
  zIndex: 10,
  position: "relative",
  background: "#fff",
  paddingTop: "7vh",
});
