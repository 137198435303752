import { createSlice } from "@reduxjs/toolkit";
import { getArticlesDraftsDataFromDB } from "../../actions/articleActions/userAsyncArticleDraftsData";

// async thunk initial state
const initialState = {
  articleDraftsData: [],
  status: "idle",
  error: null,
};

export const articleDraftsSlice = createSlice({
  name: "articleDrafts",
  initialState,
  reducers: {
    // needs to format like this. refactor.? next time.
    setArticleDrafts(state, action) {
      state.articleDraftsData = action.payload;
    },
  },
  // async thunk
  extraReducers: (builder) => {
    builder
      // needs to add authentication slices for register and login and userDetails as well.
      .addCase(getArticlesDraftsDataFromDB.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.articleDraftsData = payload;
      })
      .addCase(getArticlesDraftsDataFromDB.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getArticlesDraftsDataFromDB.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setArticleDrafts } = articleDraftsSlice.actions;

export default articleDraftsSlice.reducer;
