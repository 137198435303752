/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { setFeedbackDashboard } from "../../redux/slice/userSlice";
import { FirebaseApi } from "../../services/firebaseConfig";

const useFeedback = () => {
  const [feedback, setFeedback] = useState();
  const [reset, setReset] = useState(false);
  const [userClick, setUserClick] = useState("");
  const [feedbackData, setFeedbackData] = useState("");
  const dispatch = useDispatch();
  const userSlice = useSelector((state) => state.user);
  const { feedbackIcon, userDetails } = userSlice;
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();
  const entityName = "feedback";
  const userDataName = "user";

  const handleFeedback = () => {
    dispatch(setFeedbackDashboard(!userSlice.feedbackIcon));
    setFeedback("");
  };

  const bugBtnHandler = (value) => {
    reportBugFormik.setFieldValue("bugAt", value);
    setReset(value);
  };

  const onUserHandler = (value) => {
    setUserClick(value);
  };

  const onUserClose = () => {
    setUserClick("uuu");
  };

  const addDataToFirebase = async (values) => {
    const { component, inputValue, creatorId, id } = values;
    try {
      if (id) {
        await FirebaseApi[entityName]
          .doc(values.id)
          .update({
            component,
            inputValue,
            creatorId,
            id,
          })
          .then(() => setFeedbackData(values))
          .catch((e) => console.log(e));
      } else {
        await FirebaseApi[entityName]
          .add(values)
          .then((docRef) => setFeedbackData({ id: docRef.id, ...values }))
          .catch((e) => console.log(e));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const addInterestDataToFirebase = async (values) => {
    const { interested, creatorId } = values;
    try {
      if (creatorId) {
        await FirebaseApi[userDataName]
          .doc(creatorId)
          .update({
            interested,
          })
          .then(() => setFeedbackData(values))
          .catch((e) => console.log(e));
      }
    } catch (e) {
      console.log(e);
    }
  };
  // formik submission for feedback, feature, bug 3 separate entities

  const feedbackFormik = useFormik({
    initialValues: {
      id: "",
      inputValue: "",
      creatorId: userDetails?.keyID,
      interested: userDetails?.interested,
      component: "suggest a feed back",
    },
    onSubmit: (values) => {
      addDataToFirebase(values);
    },
  });

  const featureFormik = useFormik({
    initialValues: {
      id: "",
      inputValue: "",
      creatorId: userDetails?.keyID,
      interested: userDetails?.interested,
      component: "feature feedback",
    },
    onSubmit: (values) => {
      addDataToFirebase(values);
    },
  });
  const reportBugFormik = useFormik({
    initialValues: {
      id: "",
      bugAt: "",
      bugInput: "",
      creatorId: userDetails?.keyID,
      interested: userDetails?.interested,
    },
    onSubmit: (values) => {
      console.log(values, "bug");
      addDataToFirebase(values);
    },
  });

  return {
    day,
    month,
    year,
    reset,
    feedback,
    currentDate,
    setFeedback,
    feedbackIcon,
    bugBtnHandler,
    handleFeedback,
    onUserHandler,
    onUserClose,

    userClick,
    // formik
    feedbackFormik,
    featureFormik,
    reportBugFormik,
    addInterestDataToFirebase,
  };
};

export default useFeedback;
