import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const ChefInfo = lazy(() =>
  import(/* webpackChunkName: 'ChefPage' */ "../pages/CreatorsPage/ChefPage")
);
const CreatorArticleDisplay = lazy(() =>
  import(
    /* webpackChunkName: 'CreatorArticleDisplay' */ "../pages/CreatorsPage/ChefPage/ArticleDisplay/CreatorArticleDisplay"
  )
);
const CreatorRecipeDisplay = lazy(() =>
  import(
    /* webpackChunkName: 'CreatorRecipeDisplay' */ "../pages/CreatorsPage/ChefPage/RecipeDisplay/CreatorRecipeDisplay"
  )
);
const DomainPage = lazy(() =>
  import(
    /* webpackChunkName: 'domain-does-not-exists' */ "../components/DomainDoesnotExists"
  )
);

function SubdomainRoutes() {
  return (
    <Routes>
      <Route element={<ChefInfo />} path="/" />
      <Route
        element={<CreatorArticleDisplay />}
        path="/articleDisplay/:id"
        exact
      />
      <Route
        element={<CreatorRecipeDisplay />}
        path="/recipeDisplay/:id"
        exact
      />
      <Route element={<DomainPage />} path="/domain-does-not-exists" exact />
    </Routes>
  );
}

export default SubdomainRoutes;
