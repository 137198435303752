import { createAsyncThunk } from "@reduxjs/toolkit";
import { FirebaseApi } from "../../../services/firebaseConfig";

export const getUserSpaceDetailsData = createAsyncThunk(
  "user/getUserSpaceDetailsData",
  // eslint-disable-next-line consistent-return
  async (args) => {
    try {
      const { spaceID } = args;
      if (spaceID) {
        const data = await FirebaseApi.creatorUILayouts.doc(spaceID).get();
        const spaceData = { id: spaceID, ...data.data() };
        window.localStorage.setItem(
          "userSpaceDetails",
          JSON.stringify(spaceData)
        );
        return spaceData;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return error.response.data.message;
      }
      return error.message;
    }
  }
);
