import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useDashBoardHook = () => {
  const [currentTab, setCurrentTab] = useState("recipePublished");
  const [percentage, setPercentage] = useState(0);
  const userSlice = useSelector((state) => state.user);
  const recipeData = useSelector(
    (state) => state.recipesCollection.recipeDataRecipeCollection
  );
  const articleSnapshotData = useSelector((state) => state.article.articleData);
  const recipeFeaturesData = useSelector((state) => state.featuredRecipes);
  const { recipeFeaturedData } = recipeFeaturesData;
  const spaceDetails = useSelector((state) => state.spaceDetails);
  const subData = useSelector((state) => state.subscribers.subscriberData);
  const profileDashboardTrueRFalse = userSlice.profileDashboard;
  const { status, loading } = spaceDetails;
  const dispatch = useDispatch();

  const {
    keyID,
    lastName,
    firstName,
    photoImage,
    settingsID,
    firstTimeUserPopup,
  } = userSlice.userDetails;
  const totalPublished = [...recipeData, ...articleSnapshotData];
  const totalPublishedCount = [...totalPublished, ...recipeFeaturedData];
  const totalSteps = [photoImage, settingsID, totalPublishedCount].length;

  const percentageValue = ((percentage / totalSteps) * 100).toFixed(0);

  const handleCurrentTab = (value) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    if (photoImage && settingsID && totalPublishedCount.length > 1) {
      setPercentage(3);
    } else if (
      (photoImage && settingsID) ||
      (photoImage && totalPublishedCount.length > 1) ||
      (settingsID && totalPublishedCount.length > 1)
    ) {
      setPercentage(2);
    } else if (settingsID || photoImage || totalPublishedCount.length > 1) {
      setPercentage(1);
    }
  }, [photoImage, settingsID, totalPublishedCount]);

  useEffect(() => {}, [userSlice, status]);

  if (userSlice.userDetails === null) return;

  // eslint-disable-next-line consistent-return
  return {
    keyID,
    status,
    loading,
    subData,
    dispatch,
    lastName,
    firstName,
    photoImage,
    currentTab,
    totalSteps,
    percentage,
    settingsID,
    totalPublished,
    percentageValue,
    handleCurrentTab,
    firstTimeUserPopup,
    totalPublishedCount,
    profileDashboardTrueRFalse,
  };
};

export default useDashBoardHook;
