/* eslint-disable no-useless-catch */
import { CRUDoperations } from "../CRUD/crud";

const mealPlan = {
  create: async (userId, userName, value) => {
    try {
      const existingMealPlans = await mealPlan.read(userId);
      const userPlan = existingMealPlans[0];

      if (existingMealPlans.length > 0) {
        const update = await mealPlan.update(userPlan.id, {
          ...userPlan,
          list: [
            ...userPlan.list,
            {
              "@context": "https://schema.org/",
              "@type": "Event",
              name: `${value.name}`,
              id: `${value.id}`,
              mealType: `${value.mealType}`,
              prepTime: `${value.prepTime}`,
              cookTime: `${value.cookTime}`,
              calories: `${value.calories}`,
              author: `${value.author}`,
              duration: `${value.totalTime}`,
              imageUrl: `${value.imageUrl}`,
              eventSchedule: {
                "@type": "Schedule",
                startDate: `${value.startDate}`,
                byDay: `https://schema.org/${value.day}`,
                startTime: `${value.startTime}`,
              },
            },
          ],
        });
        return update;
      }
      const result = await CRUDoperations.create("collections", {
        userId,
        name: `${userName} mealplan`,
        type: "mealPlan",
        list: [
          {
            "@context": "https://schema.org/",
            "@type": "Event",
            name: `${value.name}`,
            id: `${value.id}`,
            mealType: `${value.mealType}`,
            prepTime: `${value.prepTime}`,
            cookTime: `${value.cookTime}`,
            calories: `${value.calories}`,
            author: `${value.author}`,
            duration: `${value.totalTime}`,
            imageUrl: `${value.imageUrl}`,
            eventSchedule: {
              "@type": "Schedule",
              startDate: `${value.startDate}`,
              byDay: `https://schema.org/${value.day}`,
            },
          },
        ],
      });
      return result;
    } catch (err) {
      throw err;
    }
  },
  read: async (userId) => {
    try {
      const Data = await CRUDoperations.search("collections", {
        keyword: [
          ["type", "==", "mealPlan"],
          ["userId", "==", `${userId}`],
        ],
      });

      return Data;
    } catch (err) {
      throw err;
    }
  },
  update: async (docId, updatedData) => {
    try {
      const updating = await CRUDoperations.update(
        "collections",
        docId,
        updatedData
      );
      return updating;
    } catch (err) {
      throw err;
    }
  },
  delete: async (docId) => {
    try {
      const deleting = await CRUDoperations.delete("collections", docId);
      return deleting;
    } catch (err) {
      throw err;
    }
  },
};

export default mealPlan;
